import client from '../request'

// useSWR, return strings
const getAll = '/events'
const getTypes = '/event-types'

// useSWR, return strings
const get = (id) => {
  return `/events/${id}`
}

// axios
const create = (data) => {
  return client.post('/events', data)
}

// axios
const update = (id, data) => {
  return client.put(`/events/${id}`, data)
}

// processing
const toRow = (data) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        stock_info: `[${element.stock.warehouse.warehouse_name}] [${element.stock.variant.fullname}]`,
        status_name: element.status.status_name,
        event_type_name: element.eventType.event_name,
        key: element.id
      }
    })
  }

  return []
}

// processing
const toPaginate = (data) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

// axios
const remove = (id) => {
  return client.delete(`/events/${id}`)
}

const EventService = {
  getAll,
  getTypes,
  get,
  create,
  update,
  toPaginate,
  toRow,
  remove
}

export default EventService
