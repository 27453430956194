import React from 'react'
import { Table, Button, Empty, Row, Col } from 'antd'
import _ from 'lodash'

import tableStore from '../../lib/store/product_table'
import TableFilter from '../../components/TableFilter'

const ProductImportTable = ({ rows, handleImport, loading }) => {
  const columns = [
    {
      title: 'Shopify ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true
    },
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'title',
      sorter: true
    },
    {
      title: 'Total Variants',
      dataIndex: 'total_variants',
      key: 'total_variants',
      sorter: true
    },
    // {
    //   title: 'SKU',
    //   dataIndex: 'sku',
    //   key: 'sku',
    //   sorter: true
    // },
    {
      title: 'Description',
      dataIndex: 'body_html',
      key: 'body_html',
      sorter: true
    },
    {
      title: 'Action',
      //dataIndex: 'id',
      key: 'action',
      render: (record) => {
        return (
          <div>
            <Button onClick={(e) => handleImport([record.id])} loading={loading}>
              Import
            </Button>
          </div>
        )
      }
    }
  ]

  const filterColumns = [
    { key: 'title', name: 'Name' },
    { key: 'id', name: 'ID' },
    { key: 'body_html', name: 'Description' }
  ]
  filterColumns.forEach((item) => {
    if (tableStore.state?.filters && Object.keys(tableStore.state.filters).includes(item.key)) {
      item.default_value = tableStore.state.filters[item.key]
      item.default = true
    }
  })

  return (
    <>
      <Row>
        <Col span={6}></Col>
        <Col span={18}>
          <TableFilter columns={filterColumns} hasDate={false} />
        </Col>
      </Row>

      {rows?.length > 0 ? (
        <Table
          pagination={false}
          columns={columns}
          dataSource={rows}
          onChange={(pagination, filters, sorter) => {
            // console.log(pagination, filters, sorter)

            tableStore.state.sort =
              sorter != null && !_.isEmpty(sorter?.columnKey) && !_.isEmpty(sorter?.order)
                ? `${sorter.columnKey ?? 'created_at'}:${
                    sorter.order === 'ascend' ? 'asc' : 'desc'
                  }`
                : null

            tableStore.state.currentPage = pagination.current
            // tableStore.state.pageSize = pagination.pageSize
          }}
        />
      ) : (
        <Empty description={'No record found'} />
      )}
    </>
  )
}

export default ProductImportTable
