import client from '../request'

const register = (data) => {
  return client.post('/auth/register', data)
}

const login = (data) => {
  return client.post('/auth/login', data)
}

const logout = (data) => {
  return client.post('/auth/logout', data)
}

const changePassword = (data) => {
  return client.post(`/auth/password/change`, data)
}

const resetPassword = (data) => {
  return client.post(`/auth/password/reset`, data)
}

const checkEmail = (data) => {
  return client.post('/users/available', data)
}

const sendAuth = (data) => {
  return client.post('/auth/code', data)
}

const verifyAuth = (data) => {
  return client.post('/auth/code/verify', data)
}

const forgotPassword = (data) => {
  return client.post('/auth/forgot-password', data)
}

const sendEmailConfirmation = (data) => {
  return client.post(`/auth/resend`, data)
}

const AuthService = {
  login,
  logout,
  changePassword,
  register,
  resetPassword,
  forgotPassword,
  checkEmail,
  sendAuth,
  verifyAuth,
  sendEmailConfirmation
}

export default AuthService
