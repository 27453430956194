import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Col, Form, Input, Row, Select, message, Space, Spin } from 'antd'

import BlacklistService from '../../network/services/blacklist'

import Gaps from '../../components/Gaps'
import { useState } from 'react'
import CustomerService from '../../network/services/customer'
import useSWR from 'swr'

const BlacklistForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh,
  customers,
  handleCustomerSearch,
  updateAllowed = false,
  createAllowed = false,
  readAllowed = false,
  deleteAllowed = false
}) => {
  const navigate = useNavigate()

  const { Option } = Select
  const [customerId, setCustomerId] = useState()
  const [isReadOnly, setIsReadOnly] = useState(false)
  const { data: customer } = useSWR(customerId ? CustomerService.get(customerId) : null)

  useEffect(() => {
    if (!initialValue && customer) {
      form?.setFieldsValue({ ...customer })
      if (customer?.orders?.length > 0) {
        const order = customer.orders[0]
        form?.setFieldsValue({
          address: order.address,
          apartment: order.apartment,
          company: order.company
        })
      }
    }
  }, [customer, initialValue, form])

  useEffect(() => {
    //check the access level
    if ((initialValue != null && !readAllowed) || (initialValue === null && !createAllowed)) {
      navigate('/blacklist')
    }
  })

  useEffect(() => {
    setIsReadOnly(!updateAllowed && initialValue != null)
  }, [updateAllowed, initialValue])

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    if (initialValue?.id) {
      // update
      try {
        const { data: result } = await BlacklistService.update(initialValue.id, values)
        if (result.success) {
          message.success('Update successful')
          refresh()
        }
      } catch (error) {
        message.error('Update blacklist error ' + error.message)
      }
    } else {
      // new
      try {
        const { data: result } = await BlacklistService.create(values)
        if (result.success) {
          message.success('Create successful')
          navigate(`/dashboard/blacklist/${result.data.id}`)
        }
      } catch (error) {
        message.error('Create blacklist error ' + error.message)
      }
    }

    setIsLoading(false)
  }

  const handleCustomerChange = (value) => {
    setCustomerId(value)
  }

  return (
    <>
      <Form
        form={form}
        name="new-blacklist"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...initialValue
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
            <Card bordered={false}>
              <Form.Item
                name="customer_id"
                label="Customer"
                rules={[{ required: true, message: 'Please choose a customer' }]}
              >
                <Select
                  showSearch
                  placeholder="Select a customer with email or name"
                  onSearch={handleCustomerSearch}
                  onChange={handleCustomerChange}
                  disabled={initialValue !== null}
                  filterOption={false}
                >
                  {customers?.length > 0 &&
                    customers.map((customer) => {
                      return (
                        <Option key={customer.id} value={customer.id}>
                          <Space direction="vertical">
                            <div> {customer.name}</div>
                            <div> {customer.email}</div>
                          </Space>
                        </Option>
                      )
                    })}
                </Select>
              </Form.Item>

              {/* TODO: add all fields and autofill them when customer selected */}
              {!initialValue && customerId && !customer && <Spin />}

              {(initialValue || customer) && (
                <>
                  <Form.Item name="name" label="Name">
                    <Input disabled={isReadOnly} />
                  </Form.Item>

                  <Form.Item name="email" label="Email">
                    <Input disabled={isReadOnly} />
                  </Form.Item>

                  <Form.Item name="address" label="Address">
                    <Input disabled={isReadOnly} />
                  </Form.Item>

                  <Form.Item name="apartment" label="Apartment">
                    <Input disabled={isReadOnly} />
                  </Form.Item>

                  <Form.Item name="company" label="Company">
                    <Input disabled={isReadOnly} />
                  </Form.Item>
                </>
              )}

              <Form.Item name="remark" label="Remark">
                <Input disabled={isReadOnly} />
              </Form.Item>
            </Card>
          </Col>
        </Row>

        <Gaps />
      </Form>
    </>
  )
}

export default BlacklistForm
