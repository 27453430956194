import React, { createContext, useState } from 'react'
import { Card, PageHeader, Space, Button, Col, Row, message, Steps, Tabs } from 'antd'
import Gaps from '../components/Gaps'
import { useNavigate } from 'react-router-dom'
import { YamatoDownloadCSV, YamatoUploadCSV } from '../components/YamatoDashboard'
import DashboardOrderTable from '../components/DashboardOrderTable'
import { useTranslation } from 'react-i18next'

export const orderSelectContext = createContext()

const Dashboard = () => {
  const [orders, setOrders] = useState([])
  const [currentStep, setCurrentStep] = useState(0)
  const [tab, setTab] = useState('1')
  const [orderIds, setOrderIds] = useState([])
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard' })
  const { t: generalT } = useTranslation('translation')
  //requried to pass message as a prop as it doesnt work in deeper component
  const msg = message
  const navigate = useNavigate()

  return (
    <orderSelectContext.Provider value={{ orderIds, setOrderIds }}>
      <PageHeader title="Dashboard">
        <Tabs
          defaultActiveKey="1"
          activeKey={tab}
          onChange={(key) => setTab(key)}
          type="card"
          tabBarStyle={{ marginBottom: 0 }}
        >
          {/* TODO: make the tab content into another component */}
          <Tabs.TabPane key="1" tab={generalT('Yamato')}>
            <Card bordered={false}>
              <Row>
                <Col>
                  <Steps current={currentStep}>
                    <Steps.Step
                      style={{ cursor: 'pointer' }}
                      title="Step 1"
                      description={t('step1')}
                      onClick={() => setCurrentStep(0)}
                    />
                    <Steps.Step
                      style={{ cursor: 'pointer' }}
                      title="Step 2"
                      description={t('step2')}
                      onClick={() => setCurrentStep(1)}
                    />
                    <Steps.Step
                      style={{ cursor: 'pointer' }}
                      title="Step 3"
                      description={t('step3')}
                      onClick={() => setCurrentStep(2)}
                    />
                    <Steps.Step
                      style={{ cursor: 'pointer' }}
                      title="Step 4"
                      description={t('step4')}
                      onClick={() => setCurrentStep(3)}
                    />
                  </Steps>
                </Col>
              </Row>
              <Gaps />
              {currentStep > 0 && (
                <Button
                  onClick={() => setCurrentStep(currentStep - 1)}
                  type="primary"
                  style={{ marginRight: 8 }}
                >
                  Back
                </Button>
              )}
              {currentStep < 3 && (
                <Button onClick={() => setCurrentStep(currentStep + 1)} type="primary">
                  Next
                </Button>
              )}
              {currentStep === 3 && (
                <Button onClick={() => setCurrentStep(0)} type="primary">
                  Finish
                </Button>
              )}
              <Gaps />
              {currentStep === 0 && (
                <Card>
                  <YamatoDownloadCSV message={msg} />
                </Card>
              )}
              {currentStep === 1 && (
                <Space direction="vertical">
                  <Button
                    type="primary"
                    shape="round"
                    size={'large'}
                    onClick={() => {
                      window.open('https://bmypage.kuronekoyamato.co.jp/bmypage/')
                    }}
                  >
                    {generalT('Yamato')}
                  </Button>
                </Space>
              )}
              {/* <Card>
              <Space>Step 3 : Download Shipping Label and CSV from Yamato</Space>
            </Card> */}
              {currentStep === 3 && (
                <>
                  <Card>
                    <YamatoUploadCSV setOrders={setOrders} />
                  </Card>

                  {orders?.length > 0 && (
                    <Card>
                      <h3>Updated tracking code for the following orders</h3>
                      <Space>
                        {orders.map((order, index) => (
                          <Card key={order.id ?? index}>
                            <Row>
                              <Col>{order.id}</Col>
                              <Col>
                                <Button
                                  type="primary"
                                  onClick={() => navigate(`order/${order.id}`)}
                                >
                                  View
                                </Button>
                              </Col>
                            </Row>
                          </Card>
                        ))}
                      </Space>
                    </Card>
                  )}
                </>
              )}
            </Card>
          </Tabs.TabPane>
          <Tabs.TabPane key="2" tab={generalT('Sagawa')}>
            <Card bordered={false}></Card>
          </Tabs.TabPane>
          <Tabs.TabPane key="3" tab={generalT('Nippon Express')}>
            <Card bordered={false}></Card>
          </Tabs.TabPane>
        </Tabs>
        <Gaps />
        <DashboardOrderTable />
      </PageHeader>
    </orderSelectContext.Provider>
  )
}

export default Dashboard
