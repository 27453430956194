import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Card,
  Col,
  Form,
  Input,
  Row,
  message,
  Select,
  Rate,
  Checkbox,
  Button,
  Image,
  Divider,
  Typography,
  Upload,
  DatePicker,
  TimePicker
} from 'antd'
import moment from 'moment'

// import ThumbnailUpload from '../../components/ThumbnailUpload'

import ReviewService from '../../network/services/review'

import Gaps from '../../components/Gaps'
import _ from 'lodash'
import { DeleteFilled, PlusOutlined } from '@ant-design/icons'
import Modal from 'antd/lib/modal/Modal'
// import { useMediaQuery } from 'react-responsive'

const ReviewForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh,
  customers,
  handleCustomerSearch,
  products,
  handleProductSearch,
  updateAllowed = false,
  createAllowed = false,
  readAllowed = false,
  deleteAllowed = false
}) => {
  const { id } = useParams()
  const { Option } = Select
  const navigate = useNavigate()
  // const [currentImage, setCurrentImage] = useState(null)
  // const [imageChange, setImageChange] = useState(false)
  // const isDesktopOrLaptop = useMediaQuery({
  //   query: '(min-width: 992px)'
  // })

  const [fileList, setFileList] = useState([])
  const [isReadOnly, setIsReadOnly] = useState(false)
  const [deleteModalDisplay, setDeleteModalDisplay] = useState(false)
  const [deleteUploadId, setDeleteUploadId] = useState()
  useEffect(() => {
    //check the access level
    if ((initialValue != null && !readAllowed) || (initialValue === null && !createAllowed)) {
      navigate('/reviews')
    }
  })

  useEffect(() => {
    setIsReadOnly(!updateAllowed && initialValue != null)
  }, [updateAllowed, initialValue])

  const handlePublish = async (published) => {
    try {
      const { data: result } = await ReviewService.update(initialValue.id, {
        published
      })
      if (result.success) {
        message.success(`Successfully ${published ? 'published' : 'unpublished'} review`)
        refresh()
      }
    } catch (error) {
      message.error(`${published ? 'Publish' : 'Unpublish'} review error ` + error.message)
    }
  }

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    if (initialValue?.id) {
      // update return status
      try {
        const { data: result } = await ReviewService.update(initialValue.id, {
          ...values,
          up_votes: undefined,
          down_votes: undefined,
          up_votes_offset: values.up_votes - (initialValue.up_votes - initialValue.up_votes_offset),
          down_votes_offset:
            values.down_votes - (initialValue.down_votes - initialValue.down_votes_offset),
          product_id: _.toString(values.product_id),
          publish_date: moment(values.publish_date).toISOString()
        })
        if (result.success) {
          message.success('Update successful')
          refresh()
        }
      } catch (error) {
        message.error('Update review error ' + error.message)
      }
    } else {
      // new return status
      try {
        const formData = new FormData()
        formData.append('email', values.email)
        formData.append('name', values.name)
        formData.append('title', values.title)
        formData.append('content', values.content)
        formData.append('score', values.score)
        formData.append('up_votes_offset', values.up_votes ?? 0)
        formData.append('down_votes_offset', values.down_votes ?? 0)
        formData.append('product_id', _.toString(values.product_id))
        formData.append('publish_date', moment(values.publish_date).toISOString())
        fileList.forEach((file, index) => {
          if (file?.originFileObj) {
            formData.append(`attachments[${index}]`, file.originFileObj)
          }
        })
        const { data: result } = await ReviewService.create(formData)
        if (result.success) {
          message.success('Create successful')
          navigate(`/dashboard/reviews/${result.data.id}`)
        }
      } catch (error) {
        message.error('Create review error ' + error.message)
      }
    }

    setIsLoading(false)
  }

  const handleFileChange = (upload) => {
    setFileList(upload.fileList)
  }

  const handleFileUpload = async (upload) => {
    if (!upload.fileList || upload.fileList.length === 0) return
    try {
      const formData = new FormData()
      formData.append('attachments[0]', upload.fileList[0].originFileObj)
      const { data: result } = await ReviewService.uploadAttachments(initialValue.id, formData)
      if (result.success) {
        message.success('Upload successful')
        refresh()
      }
    } catch (error) {
      message.error('Upload error ' + error.message)
    }
  }

  const handleDeleteAttachment = (id) => {
    setDeleteModalDisplay(true)
    setDeleteUploadId(id)
  }

  return (
    <>
      <DeleteAttachmentModal
        visible={deleteModalDisplay}
        setVisible={setDeleteModalDisplay}
        id={deleteUploadId}
        refresh={refresh}
      />
      <Form
        form={form}
        name="new-source"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...initialValue,
          condition_match: initialValue == null ? 'all' : initialValue.condition_match,
          sort_order: initialValue == null ? 'ascending' : initialValue.sort_order,
          publish_date: initialValue?.publish_date ? moment(initialValue.publish_date) : undefined,
          publish_time: initialValue?.publish_date ? moment(initialValue.publish_date) : undefined
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card bordered={false}>
              {/* <Form.Item name="customer_id" label="Customer">
                <Select
                  showSearch
                  placeholder="Select a customer with email or name"
                  onSearch={handleCustomerSearch}
                  disabled={initialValue !== null}
                  filterOption={false}
                >
                  {customers?.length > 0 &&
                    customers.map((customer) => {
                      return (
                        <Option key={customer.id} value={customer.id}>
                          <Space direction="vertical">
                            <div> {customer.name}</div>
                            <div> {customer.email}</div>
                          </Space>
                        </Option>
                      )
                    })}
                </Select>
              </Form.Item> */}

              <Form.Item
                name="product_id"
                label="Product"
                rules={[{ required: true, message: 'Please choose a product' }]}
              >
                <Select
                  showSearch
                  placeholder="Select a product"
                  onSearch={handleProductSearch}
                  // disabled={initialValue !== null}
                  filterOption={false}
                >
                  {products?.length > 0 &&
                    products.map((product) => (
                      <Option key={product.id} value={product.id}>
                        {product.product_name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="publish_date"
                label="Publish Date"
                required
                rules={[{ required: true, message: 'Please choose a date' }]}
              >
                <DatePicker showTime />
              </Form.Item>

              <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true, message: 'Please insert an email' }]}
              >
                <Input readOnly={isReadOnly} />
              </Form.Item>

              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Please insert a name' }]}
              >
                <Input readOnly={isReadOnly} />
              </Form.Item>

              <Form.Item
                name="title"
                label="Title"
                rules={[{ required: true, message: 'Please insert a title' }]}
              >
                <Input readOnly={isReadOnly} />
              </Form.Item>

              <Form.Item
                name="content"
                label="Content"
                rules={[{ required: true, message: 'Please insert a content' }]}
              >
                <Input.TextArea autoSize={{ minRows: 4 }} readOnly={isReadOnly} />
              </Form.Item>

              <Form.Item
                name="score"
                label="Rating"
                rules={[{ required: true, message: 'Please pick a score' }]}
              >
                <Rate readOnly={isReadOnly} />
              </Form.Item>

              <Row gutter={[16, 16]}>
                <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }}>
                  <Form.Item
                    name="up_votes"
                    label={
                      initialValue
                        ? `Up Votes (actual votes ${
                            initialValue.up_votes - initialValue.up_votes_offset
                          })`
                        : 'Initial Up Votes'
                    }
                    // rules={[
                    //   {
                    //     min: 0,
                    //     message: 'The value cannot be lower than 0'
                    //   }
                    // ]}
                  >
                    <Input type="number" readOnly={isReadOnly} min={0} />
                  </Form.Item>
                </Col>

                <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }}>
                  <Form.Item
                    name="down_votes"
                    label={
                      initialValue
                        ? `Down Votes (actual votes ${
                            initialValue.down_votes - initialValue.down_votes_offset
                          })`
                        : 'Initial Down Votes'
                    }
                    // rules={[
                    //   {
                    //     min: 0,
                    //     message: 'The value cannot be lower than 0'
                    //   }
                    // ]}
                  >
                    <Input type="number" readOnly={isReadOnly} min={0} />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item label="Verified Buyer" name="verified_buyer" valuePropName="checked">
                <Checkbox />
              </Form.Item>

              {id !== 'new' && (
                <Form.Item label="Manual Create?">
                  <Checkbox checked={!!initialValue.admin_id} disabled />
                </Form.Item>
              )}

              {id !== 'new' && (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    onClick={() => handlePublish(!initialValue.published)}
                    type={initialValue.published ? 'default' : 'primary'}
                    htmlType="button"
                  >
                    {initialValue.published ? 'Unpublish' : 'Publish'}
                  </Button>
                </div>
              )}

              <Divider />
              <Typography.Text style={{ fontWeight: 'bold' }}>Uploads :</Typography.Text>
              <Gaps />
              {id !== 'new' && initialValue?.uploads && (
                <>
                  <Row gutter={[16, 16]}>
                    {initialValue.uploads.map((item) => (
                      <Col span={4}>
                        <div style={{ width: '100%', position: 'relative' }}>
                          <div
                            style={{
                              position: 'absolute',
                              zIndex: 2,
                              right: 0
                            }}
                          >
                            <Button
                              style={{ marginRight: '4px', marginTop: '4px' }}
                              onClick={() => handleDeleteAttachment(item.id)}
                            >
                              <DeleteFilled />
                            </Button>
                          </div>
                        </div>
                        <Image src={item.url} />
                      </Col>
                    ))}

                    <Col span={4}>
                      <Upload
                        listType="picture-card"
                        fileList={fileList}
                        onChange={handleFileUpload}
                      >
                        {fileList.length >= 8 ? null : (
                          <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>Upload</div>
                          </div>
                        )}
                      </Upload>
                    </Col>
                  </Row>
                </>
              )}
              {id === 'new' && (
                <Upload listType="picture-card" fileList={fileList} onChange={handleFileChange}>
                  {fileList.length >= 8 ? null : (
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  )}
                </Upload>
              )}
            </Card>
          </Col>

          {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }}>
            <ThumbnailUpload
              initialValue={initialValue}
              isLoading={isLoading}
              setCurrentImage={setCurrentImage}
              setImageChange={setImageChange}
              optional={false}
            />
          </Col> */}
        </Row>
        <Gaps />
      </Form>
    </>
  )
}

const DeleteAttachmentModal = ({ visible = false, id, setVisible, refresh }) => {
  const handleDelete = async () => {
    try {
      console.log(id)
      const { data: result } = await ReviewService.removeUpload(id)
      if (result.success) {
        message.success('Remove successful')
        refresh()
        setVisible(false)
      }
    } catch (error) {
      message.error('Remove upload error ' + error.message)
    }
  }
  return (
    <Modal
      visible={visible}
      title="Notification"
      onOk={handleDelete}
      okText={'Confirm'}
      onCancel={() => setVisible(false)}
    >
      <Typography.Paragraph>Are you sure you want to delete this image?</Typography.Paragraph>
    </Modal>
  )
}

export default ReviewForm
