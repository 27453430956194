import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Col, Form, Input, Row, Select, message, InputNumber, Checkbox } from 'antd'

import ReturnStatusService from '../../network/services/returnStatus'

import Gaps from '../../components/Gaps'
import { useState } from 'react'

const ReturnStatusForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh,
  stocks,
  sources,
  updateAllowed = false,
  createAllowed = false,
  readAllowed = false,
  deleteAllowed = false
}) => {
  const navigate = useNavigate()

  const { Option } = Select

  const [isReadOnly, setIsReadOnly] = useState(false)
  useEffect(() => {
    //check the access level
    if ((initialValue != null && !readAllowed) || (initialValue === null && !createAllowed)) {
      navigate('/return_status')
    }
  })

  useEffect(() => {
    setIsReadOnly(!updateAllowed && initialValue != null)
  }, [updateAllowed, initialValue])

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    if (initialValue?.id) {
      // update return status
      try {
        const { data: result } = await ReturnStatusService.update(initialValue.id, values)
        if (result) {
          message.success('Update successful')
          refresh()
        }
      } catch (error) {
        message.error('Update return status error ' + error.message)
      }
    } else {
      // new return status
      try {
        const { data: result } = await ReturnStatusService.create(values)
        if (result) {
          message.success('Create successful')
          navigate(`/dashboard/returnStatus/${result.id}`)
        }
      } catch (error) {
        message.error('Create return status error ' + error.message)
      }
    }

    setIsLoading(false)
  }

  return (
    <>
      <Form
        form={form}
        name="new-return-status"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...initialValue,
          condition_match: initialValue == null ? 'all' : initialValue.condition_match,
          sort_order: initialValue == null ? 'ascending' : initialValue.sort_order
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
            <Card bordered={false}>
              <Form.Item
                name="stock_id"
                label="Stock"
                rules={[{ required: true, message: 'Please insert a stock' }]}
              >
                <Select disabled={isReadOnly}>
                  {stocks?.length > 0 &&
                    stocks.map((stock) => (
                      <Option key={stock.id} value={stock.id}>
                        {`[${stock.variant.product.product_name}]\t[${stock.warehouse_name}]\t[${stock.total_stocks} in stock]`}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="source_id"
                label="Source"
                rules={[{ required: true, message: 'Please insert a source' }]}
              >
                <Select disabled={isReadOnly}>
                  {sources?.length > 0 &&
                    sources.map((source) => (
                      <Option key={source.id} value={source.id}>
                        {source.source_name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="quantity"
                label="Quantity"
                rules={[{ required: true, message: 'Please insert quantity' }]}
              >
                <InputNumber min={1} readOnly={isReadOnly} />
              </Form.Item>

              <Form.Item name="is_defect" valuePropName="checked">
                <Checkbox readOnly={isReadOnly}>Is Defect</Checkbox>
              </Form.Item>

              <Form.Item name="return_reason" label="Return Reason">
                <Input.TextArea autoSize={{ minRows: 4 }} readOnly={isReadOnly} />
              </Form.Item>
            </Card>
          </Col>
        </Row>

        <Gaps />
      </Form>
    </>
  )
}

export default ReturnStatusForm
