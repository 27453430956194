import client from '../request'

//get all
const getAll = '/fulfillment-settings'

//get by id
const get = (id) => {
  return `/fulfillment-settings/${id}`
}

// axios
const create = (data) => {
  return client.post('/fulfillment-settings', data)
}

// axios
const update = (id, data) => {
  return client.put(`/fulfillment-settings/${id}`, data)
}

// axios
const remove = (id) => {
  return client.delete(`/fulfillment-settings/${id}`)
}

const toRow = (data) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id,
        is_default: element.is_default ? 'Yes' : 'No'
      }
    })
  }

  return []
}

const toPaginate = (data) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

const FulfillmentSettingService = {
  getAll,
  get,
  create,
  update,
  remove,
  toRow,
  toPaginate
}

export default FulfillmentSettingService
