import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Col, Form, Input, Row, message, Checkbox, Select } from 'antd'
import Gaps from '../../components/Gaps'
import ShopifySettingService from '../../network/services/shopifySetting'

const ShopifySettingForm = ({
  form = null,
  initialValue = null,
  shopifyLocations = [],
  isLoading = null,
  setIsLoading = null,
  refresh,
  updateAllowed = false,
  createAllowed = false,
  readAllowed = false
}) => {
  const navigate = useNavigate()

  const [isReadOnly, setIsReadOnly] = useState(false)
  useEffect(() => {
    //check the access level
    if ((initialValue != null && !readAllowed) || (initialValue === null && !createAllowed)) {
      navigate('/setting_dashboard')
    }
  })

  useEffect(() => {
    setIsReadOnly(!updateAllowed && initialValue != null)
  }, [updateAllowed, initialValue])

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    if (initialValue?.id) {
      // update
      try {
        const { data: result } = await ShopifySettingService.update(initialValue.id, values)
        if (result.success) {
          message.success('Update successful')
          refresh()
        }
      } catch (error) {
        message.error('Update yamato setting error ' + error.message)
      }
    } else {
      // new
      try {
        const { data: result } = await ShopifySettingService.create(values)
        if (result.success) {
          message.success('Create successful')
          navigate(`/dashboard/yamato_setting/${result.data.id}`)
        }
      } catch (error) {
        message.error('Create yamato setting error ' + error.message)
      }
    }

    setIsLoading(false)
  }

  return (
    <>
      <Form
        form={form}
        name="new-yamato-setting"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...initialValue
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
            <Card bordered={false}>
              <Form.Item
                name="setting_name"
                label="Setting Name"
                rules={[{ required: true, message: 'Please insert setting name' }]}
              >
                <Input readOnly={isReadOnly} />
              </Form.Item>

              <Form.Item
                name="out_location_id"
                label="Shopify warehouse"
                type="text"
                rules={[{ required: true, message: 'Please select a Shopify warehouse' }]}
              >
                <Select>
                  {console.log(shopifyLocations)}
                  {shopifyLocations?.length > 0 &&
                    shopifyLocations.map((location) => (
                      <Select.Option key={location.id} value={location.id?.toString()}>
                        {location.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item name="is_default" valuePropName="checked">
                <Checkbox disabled={isReadOnly}>Set as default Shopify setting</Checkbox>
              </Form.Item>
            </Card>
          </Col>
        </Row>

        <Gaps />
      </Form>
    </>
  )
}

export default ShopifySettingForm
