import client from '../request'

const getAll = `/users`

const get = (id) => {
  return `/users/${id}`
}

const create = (data) => {
  return client.post('/users', data)
}

const update = (id, data) => {
  return client.put(`/users/${id}`, data)
}

const updatePermissions = (id, data) => {
  return client.put(`/permissions/${id}`, data)
}

const toRow = (data) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        type: element.admin?.type ?? 0,
        key: element.id
      }
    })
  }

  return []
}

const toPaginate = (data) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

const UserService = {
  getAll,
  get,
  create,
  update,
  updatePermissions,
  toRow,
  toPaginate
}

export default UserService
