import client from '../request'

// useSWR, return strings
const getAll = (platform) => {
  return `/import/${platform}`
}

// axios
const importFrom = (platform, data) => {
  return client.post(`/import/${platform}`, data)
}

// axios
const sync = (platform, data) => {
  return client.post(`/sync/${platform}`, data)
}

// processing
const toRow = (data) => {
  if (data?.length > 0) {
    return data.map((element) => {
      return {
        ...element,
        key: element.id,
        total_variants: element?.variants?.length ?? 0
      }
    })
  }

  return []
}

const ProductImportService = {
  getAll,
  toRow,
  importFrom,
  sync: sync
}

export default ProductImportService
