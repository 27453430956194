import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Card, PageHeader, Button, message } from 'antd'
import ProductImportTable from './ImportTable'
import ProductImportService from '../../network/services/productImport'
import useSWR from 'swr'

const ProductImportPage = () => {
  const { type } = useParams()
  const [loading, setLoading] = useState(false)

  const { data: response, mutate } = useSWR(ProductImportService.getAll('shopify'))
  const rows = ProductImportService.toRow(response)

  const handleImport = async (ids) => {
    message.loading('Importing products from shopify')
    setLoading(true)
    try {
      const { data: result } = await ProductImportService.importFrom(type, {
        shopify_ids: [...ids]
      })
      if (result) {
        message.success('Import product successful')
        mutate()
      }
    } catch (error) {
      message.error('Import product error ' + error.message)
      mutate()
    }
    setLoading(false)
  }

  const primaryAction = (
    <Button
      key="import_all"
      type="primary"
      onClick={() => handleImport(rows.map((row) => row.id))}
      loading={loading}
    >
      Import All
    </Button>
  )
  console.log(rows)

  const ProductTableWrapper = () => {
    return (
      <>
        <ProductImportTable rows={rows} handleImport={handleImport} loading={loading} />
      </>
    )
  }

  return (
    <PageHeader title="Uninported Shopify Products" extra={[primaryAction]}>
      <Card>
        <ProductTableWrapper />
      </Card>
    </PageHeader>
  )
}

export default ProductImportPage
