import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Col, Form, Input, Row, Select, message, InputNumber } from 'antd'

import EventService from '../../network/services/event'

import Gaps from '../../components/Gaps'
import { useState } from 'react'

const EventForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh,
  statuses,
  eventTypes,
  sources,
  stocks,
  updateAllowed = false,
  createAllowed = false,
  readAllowed = false,
  deleteAllowed = false
}) => {
  const navigate = useNavigate()

  const { Option } = Select

  const [isReadOnly, setIsReadOnly] = useState(false)
  useEffect(() => {
    //check the access level
    if ((initialValue != null && !readAllowed) || (initialValue === null && !createAllowed)) {
      navigate('/events')
    }
  })

  useEffect(() => {
    setIsReadOnly(!updateAllowed && initialValue != null)
  }, [updateAllowed, initialValue])

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    if (initialValue?.id) {
      // update event
      try {
        const { data: result } = await EventService.update(initialValue.id, values)
        if (result.success) {
          message.success('Update successful')
          refresh()
        }
      } catch (error) {
        message.error('Update event error ' + error.message)
      }
    } else {
      // new event
      try {
        const { data: result } = await EventService.create(values)
        if (result.success) {
          console.log(result.data)
          message.success('Create successful')
          navigate(`/dashboard/events/${result.data.id}`)
        }
      } catch (error) {
        message.error('Create event error ' + error.message)
      }
    }

    setIsLoading(false)
  }

  return (
    <>
      <Form
        form={form}
        name="new-event"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...initialValue,
          status_id: initialValue?.status_id ?? 2,
          condition_match: initialValue == null ? 'all' : initialValue.condition_match,
          sort_order: initialValue == null ? 'ascending' : initialValue.sort_order
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
            <Card bordered={false}>
              <Form.Item
                name="stock_id"
                label="Stock"
                rules={[{ required: true, message: 'Please Select an exsiting stock' }]}
              >
                <Select disabled={!updateAllowed}>
                  {stocks?.length > 0 &&
                    stocks.map((stock) => (
                      <Option key={stock.id} value={stock.id}>
                        {`[${stock.variant.fullname}]\t[${stock.quality}]\t[${stock.warehouse.warehouse_name}]\t[${stock.total_stocks} in stock]`}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="quantity"
                label="Quantity"
                rules={[{ required: true, message: 'Please insert quantity' }]}
              >
                <InputNumber readOnly={initialValue !== null} />
              </Form.Item>

              {/* <Form.Item name="from" label="From">
                <Input readOnly={isReadOnly} />
              </Form.Item>

              <Form.Item name="to" label="To">
                <Input readOnly={isReadOnly} />
              </Form.Item> */}

              <Form.Item name="remark" label="Remark">
                <Input.TextArea readOnly={isReadOnly} autoSize={{ minRows: 4 }} />
              </Form.Item>

              {/* <Form.Item name="source_id" label="Source">
                <Select disabled={isReadOnly}>
                  {sources?.length > 0 &&
                    sources.map((source) => (
                      <Option key={source.id} value={source.id}>
                        {source.source_name}
                      </Option>
                    ))}
                </Select>
              </Form.Item> */}

              <Form.Item
                name="status_id"
                label="Status"
                rules={[{ required: true, message: 'Please select a status' }]}
              >
                <Select disabled={isReadOnly}>
                  {statuses?.length > 0 &&
                    statuses.map((status) => (
                      <Option key={status.id} value={status.id}>
                        {status.status_name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="event_type_id"
                label="Event type"
                rules={[{ required: true, message: 'Please select an event type' }]}
              >
                <Select disabled={isReadOnly}>
                  it
                  {eventTypes?.length > 0 &&
                    eventTypes.map((type) => (
                      <Option key={type.id} value={type.id}>
                        {type.event_name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Card>
          </Col>
        </Row>

        <Gaps />
      </Form>
    </>
  )
}

export default EventForm
