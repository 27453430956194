import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, Col, Form, Input, Row, Select, message, Button, Checkbox } from 'antd'
import { useMediaQuery } from 'react-responsive'

import ProductService from '../../network/services/product'

import Gaps from '../../components/Gaps'
import ThumbnailUpload from '../../components/ThumbnailUpload'
import ProductVariantForm from './ProductVariantForm'
import { useEffect } from 'react'

const ProductForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh,
  warehouses,
  updateAllowed = false,
  createAllowed = false,
  readAllowed = false,
  deleteAllowed = false
}) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [isAddNewVariant, setIsAddNewVariant] = useState(false)
  const [currentImage, setCurrentImage] = useState(null)
  const [imageChange, setImageChange] = useState(false)
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)'
  })

  const { Option } = Select

  const [isReadOnly, setIsReadOnly] = useState(false)
  useEffect(() => {
    //check the access level
    if ((initialValue != null && !readAllowed) || (initialValue === null && !createAllowed)) {
      navigate('/products')
    }
  })

  useEffect(() => {
    setIsReadOnly(!updateAllowed && initialValue != null)
  }, [updateAllowed, initialValue])

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    if (initialValue?.id) {
      // update product
      try {
        const { data: result } = await ProductService.update(initialValue.id, values)
        if (result.success) {
          message.success('Update successful')
          refresh()
        }
      } catch (error) {
        message.error('Update product error ' + error.message)
      }
    } else {
      // new product
      try {
        const { data: result } = await ProductService.create(values)
        if (result.success) {
          message.success('Create successful')
          navigate(`/dashboard/products/${result.data.id}`)
        }
      } catch (error) {
        message.error('Create product error ' + error.message)
      }
    }

    setIsLoading(false)
  }

  return (
    <>
      <Form
        form={form}
        name="new-product"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={
          id === 'new'
            ? {
                shopify_sync: true
              }
            : {
                ...initialValue,
                condition_match: initialValue == null ? 'all' : initialValue.condition_match,
                sort_order: initialValue == null ? 'ascending' : initialValue.sort_order
              }
        }
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
            <Card bordered={false}>
              <Form.Item
                name="product_name"
                label="Name"
                rules={[{ required: true, message: 'Please insert product name' }]}
              >
                <Input readOnly={isReadOnly} />
              </Form.Item>

              <Form.Item
                name="sku"
                label="SKU"
                rules={[{ required: true, message: 'Please insert SKU' }]}
              >
                <Input readOnly={isReadOnly} />
              </Form.Item>

              <Form.Item
                name="option_1"
                label="Option 1"
                rules={[{ required: true, message: 'Please insert at least an option' }]}
              >
                <Input readOnly={isReadOnly} />
              </Form.Item>

              <Form.Item name="option_2" label="Option 2">
                <Input readOnly={isReadOnly} />
              </Form.Item>

              <Form.Item name="option_3" label="Option 3">
                <Input readOnly={isReadOnly} />
              </Form.Item>

              {id === 'new' && (
                <Form.Item name="warehouse_id" label="Warehouse">
                  <Select disabled={isReadOnly}>
                    {warehouses?.length > 0 &&
                      warehouses.map((warehouse) => (
                        <Option key={warehouse.id} value={warehouse.id}>
                          {warehouse.warehouse_name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              )}

              <Form.Item name="short_description" label="Short Description">
                <Input.TextArea readOnly={isReadOnly} autoSize={{ minRows: 4 }} />
              </Form.Item>

              {id === 'new' && (
                <Form.Item name="shopify_sync" valuePropName="checked">
                  <Checkbox> Sync to Shopify? </Checkbox>
                </Form.Item>
              )}
            </Card>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }}>
            <ThumbnailUpload
              initialValue={initialValue}
              isLoading={isLoading}
              setCurrentImage={setCurrentImage}
              setImageChange={setImageChange}
              optional={false}
            />
          </Col>
        </Row>
      </Form>

      <Gaps />
      {id !== 'new' && (
        <Button
          key={'add_new_variant'}
          type="primary"
          onClick={() => setIsAddNewVariant(true)}
          loading={isLoading}
        >
          Add a variant
        </Button>
      )}
      <Gaps />
      {id !== 'new' && (
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
            {isAddNewVariant && (
              <ProductVariantForm
                warehouses={warehouses}
                productId={id}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                refresh={refresh}
              />
            )}
          </Col>
        </Row>
      )}
      <Gaps />

      {id !== 'new' && (
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
            <Card bordered={false}>
              {initialValue?.variants?.length > 0 &&
                initialValue.variants.map((variant) => (
                  <ProductVariantForm
                    key={variant.id}
                    initialValue={variant}
                    productId={id}
                    id={variant.id}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    refresh={refresh}
                  />
                ))}
            </Card>
          </Col>
        </Row>
      )}
    </>
  )
}

export default ProductForm
