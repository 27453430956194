import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Col, Form, Input, Row, message, Checkbox } from 'antd'

import WarehouseService from '../../network/services/warehouse'

import Gaps from '../../components/Gaps'

const WarehouseForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh,
  updateAllowed = false,
  createAllowed = false,
  readAllowed = false,
  deleteAllowed = false
}) => {
  const navigate = useNavigate()

  const [isReadOnly, setIsReadOnly] = useState(false)
  useEffect(() => {
    //check the access level
    if ((initialValue != null && !readAllowed) || (initialValue === null && !createAllowed)) {
      navigate('/events')
    }
  })

  useEffect(() => {
    setIsReadOnly(!updateAllowed && initialValue != null)
  }, [updateAllowed, initialValue])

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    if (initialValue?.id) {
      // update warehouse
      try {
        const { data: result } = await WarehouseService.update(initialValue.id, values)
        if (result) {
          message.success('Update successful')
          refresh()
        }
      } catch (error) {
        message.error('Update warehouse error ' + error.message)
      }
    } else {
      // new warehouse
      try {
        const { data: result } = await WarehouseService.create(values)
        if (result.success) {
          message.success('Create successful')
          navigate(`/dashboard/warehouses/${result.data.id}`)
        }
      } catch (error) {
        message.error('Create warehouse error ' + error.message)
      }
    }

    setIsLoading(false)
  }

  return (
    <>
      <Form
        form={form}
        name="new-warehouse"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...initialValue,
          condition_match: initialValue == null ? 'all' : initialValue.condition_match,
          sort_order: initialValue == null ? 'ascending' : initialValue.sort_order
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
            <Card bordered={false}>
              <Form.Item
                name="warehouse_name"
                label="Name"
                rules={[{ required: true, message: 'Please insert warehouse name' }]}
              >
                <Input readOnly={isReadOnly} />
              </Form.Item>

              <Form.Item
                name="location"
                label="Location"
                rules={[{ required: true, message: 'Please insert warehouse location' }]}
              >
                <Input readOnly={isReadOnly} />
              </Form.Item>

              <Form.Item
                name="address"
                label="Address"
                rules={[
                  {
                    required: true,
                    message: 'Please insert warehouse description'
                  }
                ]}
              >
                <Input.TextArea autoSize={{ minRows: 4 }} readOnly={isReadOnly} />
              </Form.Item>

              <Form.Item name="is_default" valuePropName="checked">
                <Checkbox readOnly={isReadOnly}>Is Default</Checkbox>
              </Form.Item>
            </Card>
          </Col>
        </Row>

        <Gaps />
      </Form>
    </>
  )
}

export default WarehouseForm
