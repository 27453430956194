import { Typography } from 'antd'
import Gaps from './Gaps'

const CopyableFormItem = ({ title, content, enableCopy = true }) => {
  return (
    <>
      <div>
        <div>{title}</div>
        <div style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', minHeight: 30 }}>
          <Typography.Text copyable={content !== null && content !== undefined}>
            {content ?? '-'}
          </Typography.Text>
        </div>
      </div>
      <Gaps />
    </>
  )
}

export default CopyableFormItem
