import invoicePdfClient from '../invoicePdfClient'
import { serialize } from '../request'

// useSWR, return strings
const getRyoushuusho = (data) => {
  return invoicePdfClient.get(serialize('/ryoushuusho', data))
}

const getSeikyuusho = (data) => {
  return invoicePdfClient.get(serialize('/seikyuusho', data))
}

const getNouhinsho = (data) => {
  return invoicePdfClient.get(serialize('/nouhinsho', data))
}

const InvoicePdfService = {
  getRyoushuusho,
  getSeikyuusho,
  getNouhinsho
}

export default InvoicePdfService
