import React from 'react'
import { Card, Col, Form, Input, Row, Checkbox, message } from 'antd'

import Gaps from '../../components/Gaps'
import { useNavigate, useParams } from 'react-router-dom'
import UserService from '../../network/services/user'

const UserForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh
}) => {
  console.log(initialValue)
  const navigate = useNavigate()
  const { id } = useParams()

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    // if (videoChange) {
    //   const upload = await FileService.create(currentVideo)

    //   if (upload.success === true) {
    //     const duration = await getVideoMeta(upload.data)
    //     values['duration'] = duration
    //   }

    //   values['video_url'] = upload.data
    // }

    // if (imageChange) {
    //   const upload = await FileService.create(currentImage)
    //   values['thumbnail_url'] = upload.data
    // }
    try {
      const { data: result } = await UserService.updatePermissions(initialValue.id, values)
      if (result) {
        message.success('Update user successful')
        refresh()
      }
    } catch (error) {
      message.error('Update user error ' + error.message)
    }
  }

  setIsLoading(false)

  return (
    <>
      <Form
        form={form}
        name="new-user"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...initialValue,
          address: (
            initialValue?.profile?.address_1 +
            ' ' +
            initialValue?.profile?.address_2 +
            ' ' +
            initialValue?.profile?.postcode
          ).trim(),
          condition_match: initialValue == null ? 'all' : initialValue.condition_match,
          sort_order: initialValue == null ? 'ascending' : initialValue.sort_order
        }}
      >
        <Card bordered={false}>
          <Row gutter={[16, 16]}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
              <Form.Item name="email" label="Email">
                <Input readOnly />
              </Form.Item>

              <Form.Item name="name" label="Name">
                <Input readOnly />
              </Form.Item>

              <Form.Item name="phone" label="Phone Number">
                <Input readOnly />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>Permissions</Row>
          <Gaps />
          <Row gutter={[16, 16]}>
            <Col xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 8 }}>
              General
              <Form.Item name="products_access" valuePropName="checked">
                <Checkbox>Products</Checkbox>
              </Form.Item>
              <Form.Item name="reviews_access" valuePropName="checked">
                <Checkbox>Reviews</Checkbox>
              </Form.Item>
              <Form.Item name="stocks_access" valuePropName="checked">
                <Checkbox>Stocks</Checkbox>
              </Form.Item>
              <Form.Item name="events_access" valuePropName="checked">
                <Checkbox>Events</Checkbox>
              </Form.Item>
              <Form.Item name="warehouses_access" valuePropName="checked">
                <Checkbox>Warehouses</Checkbox>
              </Form.Item>
              <Form.Item name="returns_access" valuePropName="checked">
                <Checkbox>Returns</Checkbox>
              </Form.Item>
              <Form.Item name="orders_access" valuePropName="checked">
                <Checkbox>Orders</Checkbox>
              </Form.Item>
              <Form.Item name="orders_edit_access" valuePropName="checked">
                <Checkbox>Orders Edit</Checkbox>
              </Form.Item>
            </Col>
            <Col xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 8 }}>
              Administration
              <Form.Item name="users_access" valuePropName="checked">
                <Checkbox>Users</Checkbox>
              </Form.Item>
              <Form.Item name="user_permissions_access" valuePropName="checked">
                <Checkbox>User Permissions</Checkbox>
              </Form.Item>
              <Form.Item name="sources_access" valuePropName="checked">
                <Checkbox>Sources</Checkbox>
              </Form.Item>
              <Form.Item name="customers_access" valuePropName="checked">
                <Checkbox>Customers</Checkbox>
              </Form.Item>
              <Form.Item name="blacklists_access" valuePropName="checked">
                <Checkbox>Blacklists</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Gaps />
      </Form>
    </>
  )
}

export default UserForm
