import client from '../request'

const getAll = '/feedbacks'

const get = (id) => {
  return `/feedbacks/${id}`
}

const getHistory = (id) => {
  return `/feedbacks/${id}/histories`
}

const create = (data) => {
  return client.post('/feedbacks', data)
}

const createHistory = (id, data) => {
  return client.post(`/feedbacks/${id}/histories`, data)
}

// processing
const toRow = (data) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

// processing
const historyToRow = (data) => {
  if (data?.length > 0) {
    return data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

// processing
const toPaginate = (data) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

// processing
const historyToPaginate = (data) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

const FeedbackService = {
  getAll,
  get,
  getHistory,
  create,
  createHistory,
  toRow,
  historyToRow,
  toPaginate,
  historyToPaginate
}

export default FeedbackService
