import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Col, Form, Input, Row, message, Checkbox } from 'antd'

import FulfillmentSettingService from '../../network/services/fulfillmentSetting'

import Gaps from '../../components/Gaps'

const FulfillmentSettingForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh,
  updateAllowed = false,
  createAllowed = false,
  readAllowed = false
}) => {
  const navigate = useNavigate()

  const [isReadOnly, setIsReadOnly] = useState(false)
  useEffect(() => {
    //check the access level
    if ((initialValue != null && !readAllowed) || (initialValue === null && !createAllowed)) {
      navigate('/setting_dashboard')
    }
  })

  useEffect(() => {
    setIsReadOnly(!updateAllowed && initialValue != null)
  }, [updateAllowed, initialValue])

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    if (initialValue?.id) {
      // update
      try {
        const { data: result } = await FulfillmentSettingService.update(initialValue.id, values)
        if (result.success) {
          message.success('Update successful')
          refresh()
        }
      } catch (error) {
        message.error('Update fulfillment setting error ' + error.message)
      }
    } else {
      // new
      try {
        const { data: result } = await FulfillmentSettingService.create(values)
        if (result.success) {
          message.success('Create successful')
          navigate(`/dashboard/fulfillment_setting/${result.data.id}`)
        }
      } catch (error) {
        message.error('Create fulfillment setting error ' + error.message)
      }
    }

    setIsLoading(false)
  }

  return (
    <>
      <Form
        form={form}
        name="new-fulfillment-setting"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...initialValue,
          condition_match: initialValue == null ? 'all' : initialValue.condition_match,
          sort_order: initialValue == null ? 'ascending' : initialValue.sort_order
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
            <Card bordered={false}>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Please insert name' }]}
              >
                <Input readOnly={isReadOnly} />
              </Form.Item>

              <Form.Item
                name="phone"
                label="Phone"
                rules={[{ required: true, message: 'Please insert phone number' }]}
              >
                <Input readOnly={isReadOnly} />
              </Form.Item>

              <Form.Item
                name="postal"
                label="Postal"
                rules={[{ required: true, message: 'Please insert a postal code' }]}
              >
                <Input readOnly={isReadOnly} />
              </Form.Item>

              <Form.Item
                name="province"
                label="Province"
                rules={[{ required: true, message: 'Please insert a province' }]}
              >
                <Input readOnly={isReadOnly} />
              </Form.Item>

              <Form.Item
                name="city"
                label="City"
                rules={[{ required: true, message: 'Please insert a city' }]}
              >
                <Input readOnly={isReadOnly} />
              </Form.Item>

              <Form.Item
                name="address_1"
                label="Address 1"
                rules={[{ required: true, message: 'Please insert the first line of the address' }]}
              >
                <Input readOnly={isReadOnly} />
              </Form.Item>

              <Form.Item name="address_2" label="Address 2">
                <Input readOnly={isReadOnly} />
              </Form.Item>

              <Form.Item
                name="setting_name"
                label="Setting Name"
                rules={[{ required: true, message: 'Please insert setting name' }]}
              >
                <Input readOnly={isReadOnly} />
              </Form.Item>

              <Form.Item name="default_item_name" label="Default Item Name">
                <Input readOnly={isReadOnly} />
              </Form.Item>

              <Form.Item name="is_default" valuePropName="checked">
                <Checkbox disabled={isReadOnly}>Set as default fulfillment setting</Checkbox>
              </Form.Item>
            </Card>
          </Col>
        </Row>

        <Gaps />
      </Form>
    </>
  )
}

export default FulfillmentSettingForm
