import client from '../request'

const getAll = '/products'

const get = (id) => {
  return `/products/${id}`
}

const getShopifyProducts = '/import/shopify'

const create = (data) => {
  return client.post('/products', data)
}

const update = (id, data) => {
  return client.put(`/products/${id}`, data)
}

const createVariant = (id, data) => {
  return client.post(`/products/${id}/variants`, data)
}

const createReview = (id, data) => {
  return client.post(`/products/${id}/reviews`, data)
}

// processing
const toRow = (data) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

// processing
const toPaginate = (data) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

const remove = (id) => {
  return client.delete(`/products/${id}`)
}

const ProductService = {
  getAll,
  get,
  getShopifyProducts,
  create,
  update,
  createVariant,
  createReview,
  toPaginate,
  toRow,
  remove
}

export default ProductService
