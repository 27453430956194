import React, { useContext, useEffect, useState } from 'react'
import {
  Col,
  Space,
  Table,
  message,
  Button,
  Row,
  Menu,
  Dropdown,
  Modal,
  Collapse,
  Checkbox,
  Empty,
  Spin,
  Card
} from 'antd'
import { CaretDownOutlined, LinkOutlined } from '@ant-design/icons'
import OrderService from '../network/services/order'
import useSWR from 'swr'
import { serialize } from '../network/request'
import { Link } from 'react-router-dom'
import Gaps from './Gaps'
import { useSnapshot } from 'valtio'
import dashboardStore from '../lib/store/order_dashboard'
import { orderSelectContext } from '../pages/Dashboard'
import tableStore from '../lib/store/dashboard_table'
import _ from 'lodash'
import TableFilter from './TableFilter'
import { useTranslation } from 'react-i18next'

const DashboardTable = ({ id }) => {
  const { orderIds, setOrderIds } = useContext(orderSelectContext)
  const [currentFilters, setCurrentFilters] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [modalLoading, setModalLoading] = useState(false)
  const [limit, setLimit] = useState(10)
  const { t } = useTranslation('translation')
  const { t: dashboardT } = useTranslation('translation', { keyPrefix: 'dashboard' })
  const { state } = useSnapshot(dashboardStore)
  const { state: tableState } = useSnapshot(tableStore)
  const {
    data: response,
    mutate,
    error
  } = useSWR(
    serialize(OrderService.getAll, {
      ...tableState[id.toString()]?.filters,
      status: id,
      page: currentPage,
      limit: limit,
      sort: tableState[id.toString()]?.sort ?? 'created_at:desc'
    })
  )
  const { total } = OrderService.toPaginate(response)
  if (total >= 0) dashboardStore.state.tabCount[id.toString()] = total

  // const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [visibleBatch, setVisibleBatch] = useState(false)
  // const [modalDisplay, setModalDisplay] = useState(false)

  const [notifyCustomer, setNotifyCustomer] = useState(true)
  const [batchData, setBatchData] = useState()
  // const [updateId, setUpdateId] = useState()
  // const [memo, setMemo] = useState()
  const orders = OrderService.toRow(response)
  const { Column } = Table
  const { Panel } = Collapse

  const filterColumns = [
    { key: 'sku', name: 'SKU' },
    { key: 'product_name', name: 'Product Name' },
    { key: 'variant_name', name: 'Variant Name' },
    { key: 'id', name: 'ID' },
    { key: 'platform_order_id', name: 'Platform Order ID' },
    { key: 'name', name: 'Name' },
    { key: 'customer_email', name: 'Customer Email' },
    { key: 'tracking', name: 'Tracking Code' },
    { key: 'address', name: 'Address' },
    { key: 'generated', name: 'Generated' }
  ]
  filterColumns.forEach((item) => {
    if (
      tableStore.state[id.toString()]?.filters &&
      Object.keys(tableStore.state[id.toString()]?.filters).includes(item.key)
    ) {
      item.default_value = tableStore.state[id.toString()]?.filters[item.key]
      item.default = true
    }
  })

  useEffect(() => {
    if (parseInt(state.tab) === id) {
      mutate()
    }
  }, [state.tab, id, mutate])

  useEffect(() => {
    if (currentFilters != null) {
      tableStore.state[id.toString()].filters = { ...currentFilters }
      // tableStore.state[id.toString()].currentPage = 1
    }
  }, [currentFilters, id])

  const rowSelection = {
    selectedRowKeys: orderIds,
    onChange: (keySelected) => {
      //setSelectedRowKeys(keySelected)
      setOrderIds(keySelected)
    }
  }

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response) {
    return <Spin />
  }

  // const handleAction = (orderId, values) => {
  //   setBatchData(values)
  //   setUpdateId(orderId)
  //   setMemo(orders[orderId - 1])
  //   setModalDisplay(true)
  // }

  // const SingleModel = () => {
  //   return (
  //     <Modal
  //       title={`Change order status`}
  //       visible={modalDisplay}
  //       onOk={() => {
  //         const key = parseInt(batchData)
  //         handleSubmit(updateId, key)
  //         setModalDisplay(false)
  //       }}
  //       onCancel={() => {
  //         setModalDisplay(false)
  //       }}
  //       okText="Ok"
  //       cancelText="Cancel"
  //     >
  //       <Collapse>
  //         <Panel header="Customer Memo">{memo ? <p>{memo.customer_memo}</p> : null}</Panel>
  //         <Panel header="Operator Memo">{memo ? <p>{memo.operator_memo}</p> : null}</Panel>
  //       </Collapse>
  //     </Modal>
  //   )
  // }

  //TODO: batch move to shipped update
  const handleSubmitShipped = async (id) => {
    try {
      const { data: result } = await OrderService.orderFulfillment(id, {
        notify_customer: notifyCustomer
      })
      if (result.success) {
        message.success('Order Fulfillment successful')
      }
    } catch (error) {
      message.error('Order Fulfillment error ' + error.message)
    }
  }

  const handleSubmit = async (orderId, status_key) => {
    const data = {
      order_status_id: status_key,
      notify_customer: notifyCustomer
    }

    //different api is needed to fulfill an order
    if (status_key === 6) {
      await handleSubmitShipped(orderId)
      return
    }

    try {
      const { data: result } = await OrderService.update(orderId, data)
      if (result.success) {
        message.success('Update successful')
      }
    } catch (error) {
      message.error('Update order error ' + error.message)
    }
  }

  const handleBatch = async (e) => {
    setBatchData(e.key)
    setVisibleBatch(true)
  }

  const BatchModel = () => {
    return (
      <Modal
        title={`Change ${orderIds.length} order status`}
        visible={visibleBatch}
        confirmLoading={modalLoading}
        onOk={async () => {
          setModalLoading(true)
          for (id of orderIds) {
            const key = parseInt(batchData)
            await handleSubmit(id, key)
          }
          setModalLoading(false)
          mutate()
          //setSelectedRowKeys([])
          setOrderIds([])
          setVisibleBatch(false)
        }}
        onCancel={() => {
          setVisibleBatch(false)
        }}
        okText="Ok"
        cancelText="Cancel"
      >
        {orders?.length > 0 &&
          orderIds.map((id, index) => {
            if (orders[index]) {
              const order = orders[index]
              return (
                <Collapse key={index}>
                  <Panel header={order.id}>
                    <Collapse>
                      <Panel header="Customer Memo">{order.customer_memo ?? ''}</Panel>
                      <Panel header="Operator Memo">{order.operator_memo ?? ''}</Panel>
                    </Collapse>
                  </Panel>
                </Collapse>
              )
            }
            return null
          })}
      </Modal>
    )
  }

  return (
    <>
      {/* <SingleModel /> */}
      <BatchModel />
      <Gaps />
      <Row>
        <Col span={24}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              {orderIds?.length > 0 && id !== 2 && id !== 6 && id !== 7 && (
                <Dropdown
                  overlay={
                    <Menu onClick={handleBatch}>
                      {[1, 4, 5].includes(id) && <Menu.Item key={2}>{t('Out of stock')}</Menu.Item>}
                      {[1, 3, 5].includes(id) && (
                        <Menu.Item key={3}>{t('Move to order packaging')}</Menu.Item>
                      )}
                      {id === 3 && <Menu.Item key={1}>{t('Move to restocked')}</Menu.Item>}
                      {[1, 4].includes(id) && (
                        <Menu.Item key={4}>{t('Move to waiting to be shipped')}</Menu.Item>
                      )}
                      {/* {[5, 8].includes(id) && <Menu.Item key={6}>{t('Move to shipped')}</Menu.Item>} */}
                    </Menu>
                  }
                  trigger="click"
                >
                  <Button type="primary">
                    More Actions <CaretDownOutlined />
                  </Button>
                </Dropdown>
              )}
            </div>

            <TableFilter
              columns={filterColumns}
              hasDate={false}
              setCurrentFilters={setCurrentFilters}
            />
          </div>
        </Col>

        {orderIds?.length > 0 && (
          <Checkbox checked={notifyCustomer} onClick={() => setNotifyCustomer(!notifyCustomer)}>
            {dashboardT('notifyCustomer')}
          </Checkbox>
        )}
      </Row>

      <Gaps />
      <Table
        dataSource={orders}
        rowSelection={rowSelection}
        onChange={(pagination, filters, sorter) => {
          setCurrentPage(pagination.current)
          setLimit(pagination.pageSize)
          tableStore.state[id.toString()].sort =
            sorter != null && !_.isEmpty(sorter?.columnKey) && !_.isEmpty(sorter?.order)
              ? `${sorter.columnKey ?? 'created_at'}:${sorter.order === 'ascend' ? 'asc' : 'desc'}`
              : null
        }}
        pagination={{
          showSizeChanger: true,
          total,
          pageSize: limit,
          current: currentPage,
          pageSizeOptions: [10, 20, 50, 100, 200, 300]
        }}
      >
        <Column title="Order Id" dataIndex="id" key="id" sorter />
        <Column title="Name" dataIndex="name" key="name" sorter />
        <Column
          title="Platform Order Id"
          dataIndex="platform_order_id"
          key="platform_order_id"
          sorter
        />
        <Column title="Shop Platform" dataIndex="shop_platform" key="shop_platform" />
        <Column title="Customer Name" dataIndex="customer_name" key="customer_name" sorter />
        <Column title="Total" dataIndex="total_amount" key="total_amount" sorter />
        <Column
          title="Items"
          key="items"
          render={(record) => (
            <Space direction="vertical">
              {record.line_items?.length > 0 &&
                record.line_items.slice(0, 2).map((item) => (
                  <Card bodyStyle={{ fontSize: 12, padding: 3 }}>
                    <p style={{ fontWeight: 'bold' }}>{item.sku}</p>
                    {`${item.product_title} - ${item.variant_title}`}
                  </Card>
                ))}
              {record.line_items?.length > 2 && <div>...</div>}
            </Space>
          )}
        />
        <Column
          title="Notes"
          key="items"
          render={(record) => (
            <Space direction="vertical">
              {record.note_obj?.length > 0 &&
                record.note_obj.map((item) => (
                  <Card bodyStyle={{ fontSize: 12, padding: 1 }}>
                    <p style={{ fontWeight: 'bold' }}>{item.name}</p>
                    {item.value}
                  </Card>
                ))}
              {record.line_items?.length > 2 && <div>...</div>}
            </Space>
          )}
        />
        {/* <Column title="Customer Email" dataIndex="customer_email" key="customer_email" /> */}
        {id === 1 && (
          <Column
            title="Transaction Status"
            dataIndex="transaction_status"
            key="transaction_status"
          />
        )}
        {(id === 5) | (id === 6) | (id === 8) && (
          <Column
            title="Tracking No."
            key="tracking"
            render={(record) => (
              <Space direction="vertical">
                {record.fulfillments?.length > 0 &&
                  record.fulfillments.map((item) =>
                    item.tracking_url ? (
                      <a
                        href={item.tracking_url}
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: 'underline' }}
                      >
                        {item?.tracking_number}
                        {item?.tracking_number && <LinkOutlined />}
                      </a>
                    ) : (
                      item?.tracking_number
                    )
                  )}
              </Space>
            )}
          />
        )}
        {(id === 5) | (id === 6) && (
          <Column
            title="Tracking Company"
            key="tracking_company_name"
            render={(record) => {
              const trackingCompanies = record.fulfillments.map(
                (item) => item.tracking_company_name
              )
              return (
                <Space>
                  {trackingCompanies.length > 0 && _.uniq(trackingCompanies).map((item) => item)}
                </Space>
              )
            }}
          />
        )}
        <Column title="Created At" dataIndex="created_at" key="created_at" sorter />
        {id === 6 && (
          <Column title="Fulfilled At" dataIndex="fulfilled_at" key="fulfilled_at" sorter />
        )}
        {/* <Column title="Order quantity" dataIndex="quantity" key="quantity" /> */}
        {id !== 7 && (
          <Column
            title="Action"
            key="action"
            render={(record) => (
              <Space size="middle">
                <Button>
                  <Link to={'/dashboard/order/' + record.id}>View Detail</Link>
                </Button>
                {/* {id < 3 && (
                  <>
                    <Button onClick={() => handleAction(record.id, 3)}>Out of Stock</Button>
                    {id === 1 && (
                      <Button onClick={() => handleAction(record.id, 4)}>Order Packaging</Button>
                    )}
                  </>
                )}
                {id === 3 && <Button onClick={() => handleAction(record.id, 1)}>Restocked</Button>}

                {(id === 4 || id === 1) && (
                  <Button onClick={() => handleAction(record.id, 5)}>Waiting to be Shipped</Button>
                )}
                {id === 5 && <Button onClick={() => handleAction(record.id, 6)}>Shipped</Button>} */}
              </Space>
            )}
          />
        )}
      </Table>
    </>
  )
}

export default DashboardTable
