import React from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Empty, Spin, PageHeader, Button } from 'antd'
import useSWR from 'swr'

import { serialize } from '../../network/request'
import CustomerService from '../../network/services/customer'
import CustomerForm from './CustomerForm'
import OrderService from '../../network/services/order'

const CustomerDetail = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)

  const saveAction = (
    <Button
      key={'save'}
      type="primary"
      onClick={() => {
        form.submit()
      }}
      loading={isLoading}
    >
      Save
    </Button>
  )
  const discardAction = (
    <Button
      key={'discard'}
      type="ghost"
      onClick={() => {
        navigate('/dashboard/customer')
      }}
    >
      Discard
    </Button>
  )

  // use this to preload
  const {
    data: response,
    error,
    mutate
  } = useSWR(serialize(id !== 'new' ? CustomerService.get(id) : null))

  //preload order
  const { data: order } = useSWR(serialize(CustomerService.getOrder(id)))
  const orderRow = OrderService.toRow(order)

  const { data: product } = useSWR(serialize(CustomerService.getProduct(id)))

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response && id !== 'new') {
    return (
      <PageHeader>
        <Spin />
      </PageHeader>
    )
  }

  const refresh = () => {
    if (id != null) {
      if (id === 'new') {
      } else {
        mutate()
      }
    }
  }

  return (
    <PageHeader
      title={id === 'new' ? 'New Customer' : 'Edit Customer'}
      extra={[discardAction, saveAction]}
      onBack={() => navigate('/dashboard/customer')}
    >
      <CustomerForm
        form={form}
        initialValue={response}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        refresh={refresh}
        order={orderRow}
        product={product}
        updateAllowed={true}
        createAllowed={true}
        readAllowed={true}
        deleteAllowed={true}
      />
    </PageHeader>
  )
}

export default CustomerDetail
