import React from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Empty, Spin, PageHeader } from 'antd'
import useSWR from 'swr'

import { serialize } from '../../network/request'
import OrderService from '../../network/services/order'
import OrderForm from './OrderForm'

const OrderDetail = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)

  //orders are not supposed to be edited
  //TODO: to be comfirmed
  // const saveAction = (
  //   <Button
  //     key={'save'}
  //     type="primary"
  //     onClick={() => {
  //       form.submit()
  //     }}
  //     loading={isLoading}
  //   >
  //     Save
  //   </Button>
  // )
  // const discardAction = (
  //   <Button
  //     key={'discard'}
  //     type="ghost"
  //     onClick={() => {
  //       navigate('/dashboard/order')
  //     }}
  //   >
  //     Discard
  //   </Button>
  // )

  // use this to preload
  const {
    data: response,
    error,
    mutate
  } = useSWR(serialize(id !== 'new' ? OrderService.get(id) : null))

  //preload product detail
  const { data: transactionStatus } = useSWR(OrderService.getTransactionStatuses)
  const { data: orderStatus } = useSWR(OrderService.getOrderStatuses)

  const transactionStatusRow = OrderService.transactionStatusToRow(transactionStatus)
  const orderStatusRow = OrderService.statusToRow(orderStatus)

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response && id !== 'new') {
    return (
      <PageHeader>
        <Spin />
      </PageHeader>
    )
  }

  const initialValues = {
    ...response,
    customer_email: response?.customer?.email,
    customer_name: response?.customer?.name,
    shop_platform: response?.shop_platform?.name
  }

  const refresh = () => {
    if (id != null) {
      if (id === 'new') {
      } else {
        mutate()
      }
    }
  }
  return (
    <PageHeader
      title={'View Order Detail'}
      onBack={() => navigate('/dashboard/order')}
      // extra={[discardAction, saveAction]}
    >
      <OrderForm
        form={form}
        initialValue={initialValues}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        refresh={refresh}
        transactionStatuses={transactionStatusRow}
        orderStatuses={orderStatusRow}
        updateAllowed={false}
        createAllowed={true}
        readAllowed={true}
        deleteAllowed={false}
      />
    </PageHeader>
  )
}

export default OrderDetail
