import React, { createContext, useContext, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Card,
  Col,
  Form,
  Row,
  message,
  Table,
  Select,
  Checkbox,
  Button,
  Space,
  Modal,
  Input
} from 'antd'
import invoicePdfService from '../../network/services/invoicePdf'
import OrderService from '../../network/services/order'
import Gaps from '../../components/Gaps'
import { useState } from 'react'
import { Option } from 'antd/lib/mentions'
import { saveAs } from 'file-saver'
import { LinkOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import CopyableFormItem from '../../components/CopyableFormItem'

const orderFormContext = createContext()

const OrderForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh,
  transactionStatuses,
  orderStatuses,
  updateAllowed = false,
  createAllowed = false,
  readAllowed = false,
  deleteAllowed = false
}) => {
  const navigate = useNavigate()

  const [isReadOnly, setIsReadOnly] = useState(false)
  const [ryoushuushoModalVisible, setRyoushuushoModalVisible] = useState(false)
  const [seikyuushoModalVisible, setSeikyuushoModalVisible] = useState(false)
  const [nouhinshoModalVisible, setNouhinshoModalVisible] = useState(false)
  useEffect(() => {
    //check the access level
    if ((initialValue != null && !readAllowed) || (initialValue === null && !createAllowed)) {
      navigate('/order')
    }
  })

  useEffect(() => {
    setIsReadOnly(!updateAllowed && initialValue != null)
  }, [updateAllowed, initialValue])

  const datetimeNotSpefied = useMemo(() => {
    if (!initialValue.note_obj) return true
    const date = initialValue.note_obj.find((item) => item.name === 'date')?.value
    const time = initialValue.note_obj.find((item) => item.name === 'time')?.value

    return !date || !time || date === '指定なし' || time === '指定なし'
  }, [initialValue])

  const productCol = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku'
    },
    {
      title: 'Name',
      key: 'name',
      render: (record) => {
        return `${record.product_title} - ${record.variant_title}`
      }
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity'
    },
    {
      title: 'Fulfilled Quantity',
      key: 'quantity',
      render: (record) => {
        return `${record.quantity - record.fulfillable_quantity} / ${record.quantity}`
      }
    }
  ]

  const fulfillmentCol = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku'
    },
    {
      title: 'Name',
      key: 'name',
      render: (record) => {
        return `${record.product_title} - ${record.variant_title}`
      }
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity'
    }
  ]

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    if (initialValue?.id) {
      // update return status
      try {
        const { data: result } = await OrderService.updateMemo(initialValue.id, values)
        if (result) {
          message.success('Update successful')
          refresh()
        }
      } catch (error) {
        message.error('Update order error ' + error.message)
      }
    }
    setIsLoading(false)
  }

  const handleSendConfirmationMail = async () => {
    if (isLoading) return
    setIsLoading(true)

    if (initialValue?.id) {
      // update return status
      try {
        const { data: result } = await OrderService.sendConfirmationEmail(initialValue.id)
        if (result) {
          message.success('Email sent')
          refresh()
        }
      } catch (error) {
        message.error('Error sending confirmation email ' + error.message)
      }
    }
    setIsLoading(false)
  }

  const handleSendDeliveryMail = async () => {
    if (isLoading) return
    setIsLoading(true)

    if (initialValue?.id) {
      // update return status
      try {
        const { data: result } = await OrderService.sendDeliveryEmail(initialValue.id)
        if (result) {
          message.success('Email sent')
          refresh()
        }
      } catch (error) {
        message.error('Error sending confirmation email ' + error.message)
      }
    }
    setIsLoading(false)
  }

  return (
    <orderFormContext.Provider value={{ initialValue }}>
      {/* TODO: temporarily disable */}
      {/* <Space>
        <Button onClick={() => setRyoushuushoModalVisible(true)}>領収書</Button>
        <Button onClick={() => setSeikyuushoModalVisible(true)}>請求書</Button>
        <Button onClick={() => setNouhinshoModalVisible(true)}>納品書</Button>
      </Space>
      <Gaps /> */}

      <Space>
        <Button
          loading={isLoading}
          disabled={!!initialValue.confirmation_mail_sent_at}
          onClick={handleSendConfirmationMail}
        >
          {initialValue.confirmation_mail_sent_at
            ? '注文確定メール発信済み'
            : '注文確定メールを発信する'}
        </Button>
        <Button
          loading={isLoading}
          disabled={!!initialValue.delivery_mail_sent_at || datetimeNotSpefied}
          onClick={handleSendDeliveryMail}
        >
          {datetimeNotSpefied
            ? '【配達希望日時は指定されていません為、発信できません。】配送時間メールを発信する'
            : initialValue.delivery_mail_sent_at
            ? '配送時間メール発信済み'
            : '配送時間メールを発信する'}
        </Button>
      </Space>
      <Gaps />

      <RyoushuushoModal
        visible={ryoushuushoModalVisible}
        onCancel={() => setRyoushuushoModalVisible(false)}
      />
      <SeikyuushoModal
        visible={seikyuushoModalVisible}
        onCancel={() => setSeikyuushoModalVisible(false)}
      />
      <NoushinshoModal
        visible={nouhinshoModalVisible}
        onCancel={() => setNouhinshoModalVisible(false)}
      />

      <Form
        form={form}
        name="new-order"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...initialValue,
          condition_match: initialValue == null ? 'all' : initialValue.condition_match,
          sort_order: initialValue == null ? 'ascending' : initialValue.sort_order
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
            <Card bordered={false}>
              <CopyableFormItem
                title="Shop Platform"
                content={initialValue.shop_platform}
                enableCopy={false}
              />
              <CopyableFormItem
                title="Platform Order Id"
                content={initialValue?.platform_order_id}
              />
              <CopyableFormItem title="Name" content={initialValue?.name} />
              <CopyableFormItem title="Customer Email" content={initialValue?.customer_email} />
              <CopyableFormItem title="Customer Name" content={initialValue?.customer_name} />
              <CopyableFormItem
                title="Shipping Name"
                content={`${initialValue?.last_name}${initialValue?.last_name ? ' ' : ''}${
                  initialValue?.first_name
                }`}
              />
              <CopyableFormItem
                title="Address"
                content={`${initialValue?.zip} ${initialValue?.address} ${
                  initialValue?.apartment ?? ''
                }`}
              />
              {/* <Form.Item name="zip" label="Zip">
                <Input readOnly />
              </Form.Item>
              <Form.Item name="address" label="Address">
                <Input readOnly />
              </Form.Item>
              <Form.Item name="apartment" label="Appartment">
                <Input readOnly />
              </Form.Item> */}
              <CopyableFormItem title="Company" content={initialValue?.company} />
              <div>
                <div>Extra info</div>
                <div
                  style={{
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: 30
                  }}
                >
                  <Row gutter={[16, 16]}>
                    {initialValue.note_obj &&
                      initialValue.note_obj?.length > 0 &&
                      initialValue.note_obj.map((note) => (
                        <Col>
                          <Card>
                            {note.name} : {note.value}
                          </Card>
                        </Col>
                      ))}
                  </Row>
                </div>
              </div>
              <Gaps />
              {/* <CopyableFormItem
                title="Tracking Company Name"
                content={initialValue?.tracking_company_name}
              /> */}
              {/* 
              <div>
                <div>Tracking Code</div>
                <div
                  style={{
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: 30
                  }}
                >
                  <div>
                    {initialValue?.tracking_url ? (
                      <a
                        href={initialValue?.tracking_url}
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: 'underline' }}
                      >
                        {initialValue?.tracking}
                        {initialValue?.tracking && <LinkOutlined />}
                      </a>
                    ) : (
                      initialValue?.tracking
                    )}
                  </div>
                </div>
              </div> */}
              <Gaps />

              <Form.Item name="transaction_status_id" label="Transaction status">
                <Select disabled={isReadOnly}>
                  {transactionStatuses?.length > 0 &&
                    transactionStatuses.map((status) => (
                      <Option key={status.id} value={status.id}>
                        {status.status}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item name="order_status_id" label="Order status">
                <Select disabled={isReadOnly}>
                  {orderStatuses?.length > 0 &&
                    orderStatuses.map((status) => (
                      <Option key={status.id} value={status.id}>
                        {status.status}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <CopyableFormItem title="Customer Memo" content={initialValue?.customer_memo} />
              <CopyableFormItem title="Operator Memo" content={initialValue?.operator_memo} />
              <Form.Item name="generated" label="Generated" valuePropName="checked">
                <Checkbox disabled>CSV Generated?</Checkbox>
              </Form.Item>

              <div>
                <div>Line Items</div>
                <Table dataSource={initialValue.line_items} columns={productCol} />
              </div>

              {initialValue?.fulfillments?.length > 0 && (
                <div>
                  <div>Fulfillments</div>
                  <Gaps />
                  {initialValue.fulfillments?.length > 0 &&
                    initialValue.fulfillments.map((item) => (
                      <div key={item.id}>
                        <Row gutter={[16, 16]}>
                          <Col>{item?.name}</Col>
                          <Col>{item?.tracking_company_name}</Col>
                          <Col>
                            {item.tracking_url ? (
                              <a
                                href={item.tracking_url}
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: 'underline' }}
                              >
                                {item?.tracking_number}
                                {item?.tracking_number && <LinkOutlined />}
                              </a>
                            ) : (
                              item?.tracking_number
                            )}
                          </Col>
                        </Row>
                        <Table dataSource={item.line_items} columns={fulfillmentCol} />
                        <Gaps />
                      </div>
                    ))}
                </div>
              )}

              <Gaps />
            </Card>
          </Col>
        </Row>

        <Gaps />
      </Form>
    </orderFormContext.Provider>
  )
}

const RyoushuushoModal = ({ onCancel, visible }) => {
  const [form] = Form.useForm()
  const { initialValue } = useContext(orderFormContext)
  const { t } = useTranslation('translation')

  const handleDownloadInvoices = async (values) => {
    message.loading(t('Downloading'))
    try {
      const { data: response } = await invoicePdfService.getRyoushuusho({
        ...values,
        order_id: initialValue.platform_order_id
      })
      const { data: file } = await axios.get(response.url, {
        responseType: 'blob'
      })
      saveAs(file, `領収書-${initialValue.name}.pdf`)
    } catch (e) {
      console.log(e)
      message.error(JSON.stringify(e))
    }
  }

  return (
    <Modal
      title="領収書"
      visible={visible}
      onCancel={() => onCancel(false)}
      onOk={form.submit}
      okText={t('Download')}
      cancelText={t('Cancel')}
    >
      <Form
        layout="vertical"
        autoComplete="off"
        form={form}
        onFinish={(values) => {
          handleDownloadInvoices(values)
          onCancel()
        }}
        initialValues={{
          title: 'お品代として',
          name: initialValue.customer_name
        }}
      >
        <Form.Item name="title" label="Title">
          <Input />
        </Form.Item>
        <Form.Item name="name" label="Name">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const SeikyuushoModal = ({ onCancel, visible }) => {
  const [form] = Form.useForm()
  const { initialValue } = useContext(orderFormContext)
  const { t } = useTranslation('translation')

  const handleDownloadInvoices = async (values) => {
    message.loading(t('Downloading'))
    try {
      const { data: response } = await invoicePdfService.getSeikyuusho({
        ...values,
        order_id: initialValue.platform_order_id
      })
      const { data: file } = await axios.get(response.url, {
        responseType: 'blob'
      })
      saveAs(file, `請求書-${initialValue.name}.pdf`)
    } catch (e) {
      const text = await e?.message?.data?.text()
      const jsonText = JSON.parse(text)
      message.error(JSON.stringify(jsonText.messages))
    }
  }

  return (
    <Modal
      title="請求書"
      visible={visible}
      onCancel={() => onCancel(false)}
      onOk={form.submit}
      okText={t('Download')}
      cancelText={t('Cancel')}
    >
      <Form
        layout="vertical"
        autoComplete="off"
        form={form}
        onFinish={(values) => {
          handleDownloadInvoices(values)
          onCancel()
        }}
        initialValues={{
          store_link: 'https://ergofinite.jp/'
        }}
      >
        <Form.Item name="store_link" label="Store Link">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const NoushinshoModal = ({ onCancel, visible }) => {
  const [form] = Form.useForm()
  const { initialValue } = useContext(orderFormContext)
  const { t } = useTranslation('translation')

  const handleDownloadInvoices = async (values) => {
    message.loading(t('Downloading'))
    try {
      const { data: response } = await invoicePdfService.getNouhinsho({
        ...values,
        order_id: initialValue.platform_order_id
      })
      const { data: file } = await axios.get(response.url, {
        responseType: 'blob'
      })
      saveAs(file, `納品書-${initialValue.name}.pdf`)
    } catch (e) {
      const text = await e?.message?.data?.text()
      const jsonText = JSON.parse(text)
      message.error(JSON.stringify(jsonText.messages))
    }
  }

  return (
    <Modal
      title="納品書"
      visible={visible}
      onCancel={() => onCancel(false)}
      onOk={form.submit}
      okText={t('Download')}
      cancelText={t('Cancel')}
    >
      <Form
        layout="vertical"
        autoComplete="off"
        form={form}
        onFinish={(values) => {
          handleDownloadInvoices(values)
          onCancel()
        }}
        initialValues={{
          store_link: 'https://ergofinite.jp/'
        }}
      >
        <Form.Item name="store_link" label="Store Link">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default OrderForm
