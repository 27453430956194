import client, { serialize } from '../request'

// useSWR, return strings
const getAll = '/fulfillments'

// useSWR, return strings
const get = (id) => {
  return `/fulfillments/${id}`
}

const fulfill = (id, data) => {
  return client.post(`/fulfillments/${id}/fulfill`, data)
}

const byTracking = (trackingCompanyNames, trackingNumber) => {
  return serialize('/fulfillments/by-tracking', {
    tracking_company_names: trackingCompanyNames.join(','),
    tracking_number: trackingNumber
  })
}

// processing
const toRow = (data) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

// processing
const toPaginate = (data) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

const FulfillmentService = {
  getAll,
  get,
  toRow,
  toPaginate,
  fulfill,
  byTracking
}

export default FulfillmentService
