import { Button, Empty, Input, message, Spin } from 'antd'
import { useState } from 'react'
import useSWR from 'swr'
import { Box, Divider, Flex, Grid, Text } from 'theme-ui'
import FeedbackService from '../../network/services/feedback'
import CustomerService from '../../network/services/customer'
import toUTCTime from '../../lib/modules/to-utc-time'

const FeedbackPage = () => {
  const [id, setId] = useState()
  const [customerEmail, setCustomerEmail] = useState()
  return (
    <Grid columns={[3, '1fr 2fr 1fr']} gap={4} sx={{ width: 'auto', m: 3, height: '90vh' }}>
      <FeedbackList id={id} setId={setId} />
      <FeedbackDetails id={id} setCustomerEmail={setCustomerEmail} />
      <CustomerDetails email={customerEmail} />
    </Grid>
  )
}

const FeedbackList = ({ id, setId }) => {
  const { data } = useSWR(FeedbackService.getAll)
  const feedbacks = FeedbackService.toRow(data)

  return (
    <Box
      sx={{
        height: 'auto',
        width: 'auto',
        overflowY: 'scroll',
        backgroundColor: 'white',
        p: 3
        // scrollbarWidth: 'none',
        // //for firefox
        // '::-webkit-scrollbar': {
        //   display: 'none'
        // }
      }}
    >
      <h3>Feedbacks</h3>
      <Divider />
      {feedbacks.map((item) => (
        <>
          <Box onClick={() => setId(item.id)} key={item.key}>
            <Box
              variant="layout.sidebarItem"
              sx={{
                bg: id === item.id ? 'bg' : 'none',
                borderRadius: 20
              }}
            >
              <Text variant="mediumSmall">
                {item.first_name} {item.last_name} <br />
                {item.email}
              </Text>
              <Text variant="large">{item.title === '' ? '<No title>' : item.title}</Text>
              <Text color="grey" variant="mediumSmall">
                {toUTCTime(item.created_at)}
              </Text>
            </Box>
          </Box>
          <Divider />
        </>
      ))}
    </Box>
  )
}

const FeedbackDetails = ({ id, setCustomerEmail }) => {
  const [content, setContent] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const { data: feedback, mutate, error } = useSWR(FeedbackService.get(id))
  if (!id) {
    return <Empty description="Select a feedback ticket to view" />
  }

  if (error) {
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!feedback) {
    return <Spin />
  }

  const handleSend = async () => {
    setIsLoading(true)
    try {
      const { data: result } = await FeedbackService.createHistory(id, {
        content: content
      })
      if (result.success) {
        mutate()
        setContent('')
      }
    } catch (e) {
      message.error('message could not be sent' + e)
    }
    setIsLoading(false)
  }

  setCustomerEmail(feedback.email)

  const histories = FeedbackService.historyToRow(feedback.histories)
  return (
    <Box
      sx={{
        // height: '100%',
        // width: '100%',
        overflowY: 'hidden',
        backgroundColor: 'white',
        position: 'relative'
      }}
    >
      <Box
        sx={{
          height: '100%',
          width: '100%',
          overflowY: 'scroll',
          p: 3,
          pb: 6,
          scrollbarWidth: 'none',
          //for firefox
          '::-webkit-scrollbar': {
            display: 'none'
          }
        }}
      >
        <h3 styles={{ fontWeight: 1000 }}>
          {feedback.title === '' ? '<No title>' : feedback.title}
        </h3>
        <Box>
          <Text color="grey" variant="mediumSmall">
            {feedback.first_name} {feedback.last_name}
          </Text>
        </Box>
        <Box>
          <Text color="grey" variant="mediumSmall">
            {feedback.email}
          </Text>
        </Box>
        <Box>
          <Text color="grey" variant="mediumSmall">
            Ticket ID : {feedback.id}
          </Text>
        </Box>
        <Box>
          <Text color="grey" variant="mediumSmall">
            Ticket created at : {toUTCTime(feedback.created_at)}
          </Text>
        </Box>
        <Box p={2} />
        <Box>
          Feedback description :
          <br />
          <Text color="grey" variant="mediumSmall" as="p" sx={{ fontWeight: 'bold' }}>
            {feedback.content}
          </Text>
        </Box>
        <Divider mx={0} />

        {histories.map((item) => (
          <Flex
            key={item.key}
            sx={{
              my: 2,
              width: '100%',
              alignItems: 'center',
              justifyContent: item?.admin ? 'flex-end' : 'flex-start'
            }}
          >
            <Box
              sx={{
                p: 3,
                bg: item?.admin ? 'primary' : 'bg',
                maxWidth: '50%',
                borderRadius: item?.admin ? '20px 0px 20px 20px' : '0px 20px 20px 20px'
              }}
            >
              <Text sx={{ wordWrap: 'break-word' }}>{item.content}</Text>
            </Box>
          </Flex>
        ))}
      </Box>

      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          p: 3,
          bg: '#e2e2e2',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Input onChange={(e) => setContent(e.target.value)} disabled={isLoading} value={content} />
        <Button type="primary" style={{ marginLeft: 4 }} onClick={handleSend} loading={isLoading}>
          Send
        </Button>
      </Box>
    </Box>
  )
}

const CustomerDetails = ({ email }) => {
  const { data } = useSWR(CustomerService.getByEmail(email))

  if (!email) {
    return <></>
  }

  if (!data) {
    return <Spin />
  }

  return (
    <Box
      sx={{
        height: '100%',
        width: 'auto',
        overflowY: 'scroll',
        backgroundColor: 'white',
        p: 3
        // scrollbarWidth: 'none',
        // //for firefox
        // '::-webkit-scrollbar': {
        //   display: 'none'
        // }
      }}
    ></Box>
  )
}

export default FeedbackPage
