// useSWR, return strings
const getAll = '/recruitments'

// useSWR, return strings
const get = (id) => {
  return `/recruitments/${id}`
}

// processing
const toRow = (data) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

// processing
const toPaginate = (data) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

const RecruitmentService = {
  getAll,
  get,
  toPaginate,
  toRow
}

export default RecruitmentService
