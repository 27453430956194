import React from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Empty, Spin, PageHeader, Button } from 'antd'
import useSWR from 'swr'

import { serialize } from '../../network/request'
import BlacklistService from '../../network/services/blacklist'
import BlacklistForm from './BlacklistForm'
import CustomerService from '../../network/services/customer'
const shiftCharCode = (Δ) => (c) => String.fromCharCode(c.charCodeAt(0) + Δ)
const toHalfWidth = (str) => str?.replace(/[！-～]/g, shiftCharCode(-0xfee0))

const BlacklistDetail = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [customerSearch, setCustomerSearch] = useState('')
  const [delaySearch, setDelaySearch] = useState()

  const saveAction = (
    <Button
      key={'save'}
      type="primary"
      onClick={() => {
        form.submit()
      }}
      loading={isLoading}
    >
      Save
    </Button>
  )
  const discardAction = (
    <Button
      key={'discard'}
      type="ghost"
      onClick={() => {
        navigate('/dashboard/blacklist')
      }}
    >
      Discard
    </Button>
  )

  const handleCustomerSearch = (value) => {
    clearTimeout(delaySearch)
    const asciiValue = value?.length > 0 ? toHalfWidth(value) : ''
    const deplayDebounce = setTimeout(() => {
      setCustomerSearch(asciiValue)
    }, 500)
    setDelaySearch(deplayDebounce)
  }

  // use this to preload
  const {
    data: response,
    error,
    mutate
  } = useSWR(serialize(id !== 'new' ? BlacklistService.get(id) : null))

  //preload the dropdown menu content
  const { data: customer } = useSWR(
    serialize(CustomerService.getAll, {
      search: customerSearch
    })
  )
  const customerRows = CustomerService.toRow(customer)

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response && id !== 'new') {
    return (
      <PageHeader>
        <Spin />
      </PageHeader>
    )
  }

  const refresh = () => {
    if (id != null) {
      if (id === 'new') {
      } else {
        mutate()
      }
    }
  }

  return (
    <PageHeader
      title={id === 'new' ? 'New Blacklist' : 'Edit Blacklist'}
      extra={[discardAction, saveAction]}
      onBack={() => navigate('/dashboard/blacklist')}
    >
      <BlacklistForm
        form={form}
        initialValue={response}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        refresh={refresh}
        customers={customerRows}
        handleCustomerSearch={handleCustomerSearch}
        updateAllowed={true}
        createAllowed={true}
        readAllowed={true}
        deleteAllowed={true}
      />
    </PageHeader>
  )
}

export default BlacklistDetail
