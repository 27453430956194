import { Button, PageHeader, Typography } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const StockScanner = () => {
  const { Title, Paragraph } = Typography
  const navigate = useNavigate()

  return (
    <PageHeader title=" " onBack={() => navigate('/dashboard')}>
      <div style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
        <Typography>
          <Title>Scan Barcode</Title>
          <Paragraph>
            Place the barcode inside the frame to scan, Please keep your device steady when scanning
            to ensure accurate result
          </Paragraph>
        </Typography>
        <Button onClick={() => navigate('handheld')}>Scan with Handheld scanner</Button>
        <Button>Scan with mobile camera</Button>
      </div>
    </PageHeader>
  )
}

export default StockScanner
