import { PageHeader, Tabs, Card } from 'antd'
import { useSnapshot } from 'valtio'
import settingStore from '../lib/store/app_setting'
import FulfillmentSettingPage from './fulfillmentSetting'
import ShopifySettingPage from './shopifySetting'
import YamatoSettingPage from './yamatoSetting'

const SettingDashboard = () => {
  const { TabPane } = Tabs
  const { state } = useSnapshot(settingStore)

  return (
    <PageHeader title="Settings Dashboard">
      <Card>
        <Tabs
          defaultActiveKey="1"
          activeKey={state.tab}
          type="card"
          onChange={(key) => (settingStore.state.tab = key)}
        >
          <TabPane tab="Fulfillment Settings" key="1">
            <FulfillmentSettingPage />
          </TabPane>
          <TabPane tab="Yamato Settings" key="2">
            <YamatoSettingPage />
          </TabPane>
          <TabPane tab="Shopify Settings" key="3">
            <ShopifySettingPage />
          </TabPane>
        </Tabs>
      </Card>
    </PageHeader>
  )
}

export default SettingDashboard
