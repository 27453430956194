import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, PageHeader, Button, Empty, Spin } from 'antd'
import useSWR from 'swr'
import { useSnapshot } from 'valtio'

import tableStore from '../../lib/store/warehouse_table'
import WarehouseService from '../../network/services/warehouse'
import { serialize } from '../../network/request'
import WarehouseTable from './WarehouseTable'

const WarehousePage = () => {
  const navigate = useNavigate()
  const primaryAction = (
    <Button
      key="add"
      type="primary"
      onClick={() => {
        navigate('new')
      }}
    >
      Create Warehouse
    </Button>
  )

  return (
    <PageHeader title="All Warehouse" extra={[primaryAction]}>
      <Card>
        <WarehouseTableWrapper />
      </Card>
    </PageHeader>
  )
}

const WarehouseTableWrapper = () => {
  const { state } = useSnapshot(tableStore)

  // use this to preload
  const { data: response, error } = useSWR(
    serialize('/warehouses', { page: 1, limit: state.pageSize })
  )

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response) {
    return <Spin />
  }

  const { total } = WarehouseService.toPaginate(response)

  return (
    <>
      <WarehouseTable total={total} />
      {/* preload next page */}
      {state.currentPage * state.pageSize < total && (
        <div style={{ display: 'none' }}>
          <WarehouseTable total={total} overridePage={state.currentPage + 1} />
        </div>
      )}
    </>
  )
}

export default WarehousePage
