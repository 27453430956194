import React from 'react'
import ReactDOM from 'react-dom'
import { SWRConfig } from 'swr'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { fetcher } from './network/request'
import './i18n'

ReactDOM.render(
  <React.StrictMode>
    <SWRConfig value={{ fetcher, revalidateOnFocus: false, errorRetryCount: 3 }}>
      <App />
    </SWRConfig>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
