import client from '../request'

// useSWR, return strings
const getAll = '/stocks'

// useSWR, return strings
const get = (id) => {
  return `/stocks/${id}`
}

// useSWR, return strings
const getQualities = (id) => {
  return `/stock-qualities`
}

// axios
const create = (data) => {
  return client.post('/stocks', data)
}

// axios
const update = (id, data) => {
  return client.put(`/stocks/${id}`, data)
}

// axios
const adjust = (id, data) => {
  return client.put(`/stocks/${id}/adjust`, data)
}

// processing
const toRow = (data) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        warehouse_name: element.warehouse.warehouse_name,
        variant_name: element.variant.fullname,
        quality: element.quality.name,
        key: element.id
      }
    })
  }

  return []
}

// processing
const qualityToRow = (data) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

// processing
const toPaginate = (data) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

// axios
const remove = (id) => {
  return client.delete(`/stocks/${id}`)
}

const StockService = {
  getAll,
  get,
  getQualities,
  create,
  update,
  adjust,
  toPaginate,
  toRow,
  qualityToRow,
  remove
}

export default StockService
