import { Map } from 'immutable'

export function clearToken() {
  localStorage.removeItem('token')
}

export function clearProfile() {
  localStorage.removeItem('profile')
}

export function getToken() {
  try {
    const token = localStorage.getItem('token')
    return new Map({ token })
  } catch (err) {
    clearToken()
    return new Map()
  }
}

export function getProfile() {
  try {
    const profile = localStorage.getItem('profile')
    console.log('p', profile)
    return new Map({ profile: JSON.parse(profile) })
  } catch (err) {
    clearProfile()
    return new Map()
  }
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime)
  const milliseconds = new Date().getTime() - givenTime.getTime()
  const numberEnding = (number) => {
    return number > 1 ? 's' : ''
  }
  const number = (num) => (num > 9 ? '' + num : '0' + num)
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000)
    const years = Math.floor(temp / 31536000)
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1)
      const day = number(givenTime.getUTCDate())
      const year = givenTime.getUTCFullYear() % 100
      return `${day}-${month}-${year}`
    }
    const days = Math.floor((temp %= 31536000) / 86400)
    if (days) {
      if (days < 28) {
        return days + ' day' + numberEnding(days)
      } else {
        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ]
        const month = months[givenTime.getUTCMonth()]
        const day = number(givenTime.getUTCDate())
        return `${day} ${month}`
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600)
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`
    }
    const minutes = Math.floor((temp %= 3600) / 60)
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`
    }
    return 'a few seconds ago'
  }
  return getTime()
}

export function stringToInt(value, defValue = 0) {
  if (!value) {
    return 0
  } else if (!isNaN(value)) {
    return parseInt(value, 10)
  }
  return defValue
}

export function stringToPosetiveInt(value, defValue = 0) {
  const val = stringToInt(value, defValue)
  return val > -1 ? val : defValue
}

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export const getVideoMeta = (file) => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video')
    video.src = file
    video.onloadedmetadata = () => {
      resolve(video.duration.toFixed(2))
    }
    video.onerror = (error) => reject(error)
  })
}

const getRandomString = () => {
  if (
    window.crypto &&
    window.crypto.getRandomValues &&
    navigator.userAgent.indexOf('Safari') === -1
  ) {
    var a = window.crypto.getRandomValues(new Uint32Array(3)),
      token = ''
    for (var i = 0, l = a.length; i < l; i++) {
      token += a[i].toString(36)
    }
    return token
  } else {
    return (Math.random() * new Date().getTime()).toString(36).replace(/\./g, '')
  }
}

export const getFileName = (fileExtension) => {
  var d = new Date()
  var year = d.getFullYear()
  var month = d.getMonth()
  var date = d.getDate()
  return 'RecordRTC-' + year + month + date + '-' + getRandomString() + '.' + fileExtension
}
export const isEdge =
  navigator.userAgent.indexOf('Edge') !== -1 &&
  (!!navigator.msSaveOrOpenBlob || !!navigator.msSaveBlob)
export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
export const isAndroid = /(android)/i.test(navigator.userAgent)

export const secondsToHms = (duration) => {
  var hrs = ~~(duration / 3600)
  var mins = ~~((duration % 3600) / 60)
  var secs = ~~duration % 60

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = ''

  if (hrs > 0) {
    ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
  }

  ret += '' + mins + ':' + (secs < 10 ? '0' : '')
  ret += '' + secs
  return ret
}
