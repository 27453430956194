import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Card, Col, Form, Row } from 'antd'

import Gaps from '../../components/Gaps'
import CopyableFormItem from '../../components/CopyableFormItem'

const RecruitmentForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh,
  updateAllowed = false,
  createAllowed = false,
  readAllowed = false,
  deleteAllowed = false
}) => {
  const navigate = useNavigate()

  const [isReadOnly, setIsReadOnly] = useState(false)
  useEffect(() => {
    //check the access level
    if ((initialValue != null && !readAllowed) || (initialValue === null && !createAllowed)) {
      navigate('/recruitment')
    }
  })

  useEffect(() => {
    setIsReadOnly(!updateAllowed && initialValue != null)
  }, [updateAllowed, initialValue])

  return (
    <>
      <Form
        form={form}
        name="new-recruitment"
        layout="vertical"
        initialValues={{
          ...initialValue,
          condition_match: initialValue == null ? 'all' : initialValue.condition_match,
          sort_order: initialValue == null ? 'ascending' : initialValue.sort_order
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
            <Card bordered={false}>
              <CopyableFormItem title="First Name" content={initialValue?.first_name} />
              <CopyableFormItem title="Last Name" content={initialValue?.last_name} />
              {/* <Form.Item
                name="position"
                label="Position"
                rules={[{ required: true, message: 'Please insert position' }]}
              >
                <Input readOnly={isReadOnly} />
              </Form.Item> */}
              <CopyableFormItem title="Message" content={initialValue?.message} />
              <Form.Item
                name="attachment"
                label="Attachment"
                rules={[{ required: true, message: 'Please insert attachment' }]}
              >
                <Button
                  disabled={!initialValue.attachment_url}
                  onClick={() => {
                    window.open(`${initialValue.attachment_url}`)
                  }}
                >
                  Download Attachment
                </Button>
              </Form.Item>
            </Card>
          </Col>
        </Row>

        <Gaps />
      </Form>
    </>
  )
}

export default RecruitmentForm
