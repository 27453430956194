import React, { useEffect, useState } from 'react'
import { Table, Button, Empty, Modal, message, Row, Col, Dropdown, Menu } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { useSnapshot } from 'valtio'
import useSWR from 'swr'

import tableStore from '../../lib/store/product_table'
import TableFilter from '../../components/TableFilter'
import ProductService from '../../network/services/product'
import Gaps from '../../components/Gaps'
import ProductImportService from '../../network/services/productImport'
import { serialize } from '../../network/request'

const ProductTable = ({ total, overridePage }) => {
  const navigate = useNavigate()
  const { state } = useSnapshot(tableStore)
  const [currentFilters, setCurrentFilters] = useState(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [visible, setVisible] = useState(false)

  const { data: response, mutate } = useSWR(
    serialize(ProductService.getAll, {
      page: overridePage ?? state.currentPage,
      limit: state.pageSize,
      sort: state.sort ?? 'created_at:desc',
      refresh: state.refresh,
      ...state.filters
    })
  )
  const rows = ProductService.toRow(response)
  const rowSelection = {
    selectedRowKeys,
    onChange: (keySelected) => {
      setSelectedRowKeys(keySelected)
    }
  }

  const handleSync = async (e, productId, sync, platform) => {
    e.stopPropagation()
    try {
      const { data: result } = await ProductImportService.sync(platform, {
        ids: [productId],
        sync: sync
      })
      if (result) {
        message.success(`${sync ? 'Sync' : 'Unsync'} product successful`)
        mutate()
      }
    } catch (error) {
      message.error('Sync product error ' + error.message)
    }
  }

  const handleImport = async (e, shopifyId, platform) => {
    e.stopPropagation()
    try {
      const { data: result } = await ProductImportService.importFrom(platform, {
        shopify_ids: [shopifyId]
      })
      if (result) {
        message.success('Import product successful')
        mutate()
      }
    } catch (error) {
      message.error('Import product error ' + error.message)
    }
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true
    },
    {
      title: 'Name',
      dataIndex: 'product_name',
      key: 'product_name',
      sorter: true
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      sorter: true
    },
    {
      title: 'Total Variant',
      dataIndex: 'total_variant',
      key: 'total_variant'
      // sorter: true
    },
    // {
    //   title: 'Short Description',
    //   dataIndex: 'short_description',
    //   key: 'short_description',
    //   sorter: true
    // },
    {
      title: 'Shopify ID',
      dataIndex: 'shopify_id',
      key: 'shopify_id',
      sorter: true
    },
    {
      title: 'Action',
      //dataIndex: ['shopify_id', 'id', 'shopify_sync', 'amazon_sync'],
      key: 'action',
      render: (record) => {
        return (
          <div>
            <Row>
              <Col>
                {record.shopify_sync ? (
                  <Button
                    type="primary"
                    onClick={(e) => handleSync(e, record.id, false, 'shopify')}
                  >
                    Shopify Synced
                  </Button>
                ) : (
                  <Button onClick={(e) => handleSync(e, record.id, true, 'shopify')}>
                    Sync Shopify
                  </Button>
                )}
              </Col>
              <Gaps />
              <Col>
                <Button onClick={(e) => handleImport(e, record.shopify_id, 'shopify')}>
                  Import Shopify
                </Button>
              </Col>
            </Row>

            <Gaps />

            <Row>
              <Col>
                {record.amazon_sync ? (
                  <Button type="primary" onClick={(e) => handleSync(e, record.id, false, 'amazon')}>
                    Amazon Synced
                  </Button>
                ) : (
                  <Button onClick={(e) => handleSync(e, record.id, true, 'amazon')}>
                    Sync Amazon
                  </Button>
                )}
              </Col>
              <Gaps />
              <Col>
                <Button onClick={(e) => handleImport(e, record.shopify_id, 'amazon')}>
                  Import Amazon
                </Button>
              </Col>
            </Row>
          </div>
        )
      }
    }
  ]

  const filterColumns = [
    { key: 'product_name', name: 'Name' },
    { key: 'id', name: 'ID' },
    { key: 'short_description', name: 'Short Description' },
    { key: 'sku', name: 'SKU' }
  ]
  filterColumns.forEach((item) => {
    if (tableStore.state?.filters && Object.keys(tableStore.state.filters).includes(item.key)) {
      item.default_value = tableStore.state.filters[item.key]
      item.default = true
    }
  })

  useEffect(() => {
    if (currentFilters != null) {
      tableStore.state.filters = { ...currentFilters }
      tableStore.state.currentPage = 1
    }
  }, [currentFilters])

  const handleMenuClick = (e) => {
    if (e.key === 'delete') {
      setVisible(true)
    }
  }

  const batchDeleteRows = async () => {
    try {
      for (const id of selectedRowKeys) {
        const { data } = await ProductService.remove(id)
        if (data?.success === true) {
          message.success(`Delete product success`)
        } else {
          message.error(`Delete product ${id} failed`)
        }
        tableStore.state.refresh = Math.random()
      }

      setVisible(false)
    } catch (e) {
      message.error({ content: e.message, className: 'message-error' })
    }
  }

  const DeleteModal = () => {
    return (
      <Modal
        title={`Remove ${selectedRowKeys.length} products`}
        visible={visible}
        onOk={() => {
          batchDeleteRows()
        }}
        onCancel={() => {
          setVisible(false)
        }}
        okText="Delete"
        cancelText="Cancel"
      >
        This can't be undone
      </Modal>
    )
  }

  return (
    <>
      <DeleteModal />

      <Row>
        <Col span={6}>
          {selectedRowKeys?.length > 0 ? (
            <Dropdown
              overlay={
                <Menu onClick={handleMenuClick}>
                  <Menu.Item key="delete">Delete</Menu.Item>
                </Menu>
              }
              trigger="click"
            >
              <Button type="primary">
                More Actions <CaretDownOutlined />
              </Button>
            </Dropdown>
          ) : (
            <></>
          )}
        </Col>
        <Col span={18}>
          <TableFilter
            dropdowns={[]}
            columns={filterColumns}
            hasDate={false}
            setCurrentFilters={setCurrentFilters}
          />
        </Col>
      </Row>

      {rows?.length > 0 ? (
        <Table
          columns={columns}
          dataSource={rows}
          onChange={(pagination, filters, sorter) => {
            // console.log(pagination, filters, sorter)

            tableStore.state.sort =
              sorter != null && !_.isEmpty(sorter?.columnKey) && !_.isEmpty(sorter?.order)
                ? `${sorter.columnKey ?? 'created_at'}:${
                    sorter.order === 'ascend' ? 'asc' : 'desc'
                  }`
                : null

            tableStore.state.currentPage = pagination.current
            // tableStore.state.pageSize = pagination.pageSize
          }}
          pagination={{
            total,
            pageSize: state.pageSize,
            current: state.currentPage
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                console.log(rowIndex)
                if (selectedRowKeys?.length > 0) {
                  if (selectedRowKeys.includes(record.id)) {
                    const filterKeys = _.filter([...selectedRowKeys], function (o) {
                      return o !== record.id
                    })
                    setSelectedRowKeys([...filterKeys])
                  } else {
                    setSelectedRowKeys([...selectedRowKeys, record.id])
                  }
                } else {
                  navigate(`${record.id}`)
                }
              } // click row
            }
          }}
          rowSelection={rowSelection}
        />
      ) : (
        <Empty description={'No record found'} />
      )}
    </>
  )
}

export default ProductTable
