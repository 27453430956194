import React, { useEffect, useState } from 'react'
import { Table, Button, Empty, Modal, message, Row, Col, Dropdown, Menu, Rate } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { useSnapshot } from 'valtio'
import useSWR from 'swr'

import tableStore from '../../lib/store/review_table'
import TableFilter from '../../components/TableFilter'
import { serialize } from '../../network/request'
import ReviewService from '../../network/services/review'

const ReviewTable = ({ total, overridePage }) => {
  const navigate = useNavigate()
  const { state } = useSnapshot(tableStore)
  const [currentFilters, setCurrentFilters] = useState(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [visible, setVisible] = useState(false)

  const { data: response } = useSWR(
    serialize(ReviewService.getAll, {
      page: overridePage ?? state.currentPage,
      limit: state.pageSize,
      sort: state.sort ?? 'created_at:desc',
      refresh: state.refresh,
      ...state.filters
    })
  )
  const rows = ReviewService.toRow(response)

  const rowSelection = {
    selectedRowKeys,
    onChange: (keySelected) => {
      setSelectedRowKeys(keySelected)
    }
  }

  const columns = [
    {
      title: 'Customer Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true
      // sorter: true
    },
    {
      title: 'Product Name',
      dataIndex: 'product_name',
      key: 'product_name',
      width: 200
      // sorter: true
    },
    {
      title: 'Ratings',
      key: 'score',
      width: 180,
      sorter: true,
      render: (record) => {
        return <Rate disabled value={record.score} />
      }
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      sorter: true
    },
    {
      title: 'Up votes',
      render: (record) => {
        return `${record.up_votes} (${record.up_votes - record.up_votes_offset})`
      }
      // sorter: true
    },
    {
      title: 'Down votes',
      render: (record) => {
        return `${record.down_votes} (${record.down_votes - record.down_votes_offset})`
      }
      // sorter: true
    },
    {
      title: 'Publish Date',
      dataIndex: 'publish_date',
      key: 'publish_date',
      sorter: true,
      render: (record) => {
        const date = new Date(record)
        if (record) {
          return (
            <span>
              {date.toLocaleDateString()}{' '}
              {date.toLocaleTimeString([], {
                hour12: true,
                hour: 'numeric',
                minute: '2-digit'
              })}
            </span>
          )
        }
      }
    },
    {
      title: 'Published',
      dataIndex: 'published',
      key: 'published',
      render: (record) => {
        return record ? (
          <span style={{ color: 'green' }}>Yes</span>
        ) : (
          <span style={{ color: 'red' }}>No</span>
        )
      }
    }
  ]

  const filterColumns = [
    { key: 'title', name: 'Title' },
    { key: 'name', name: 'Name' },
    { key: 'product_name', name: 'Product Name' },
    { key: 'content', name: 'Content' },
    { key: 'score', name: 'Rating' }
  ]
  filterColumns.forEach((item) => {
    if (tableStore.state?.filters && Object.keys(tableStore.state.filters).includes(item.key)) {
      item.default_value = tableStore.state.filters[item.key]
      item.default = true
    }
  })

  useEffect(() => {
    if (currentFilters != null) {
      tableStore.state.filters = { ...currentFilters }
      tableStore.state.currentPage = 1
    }
  }, [currentFilters])

  const handleMenuClick = (e) => {
    if (e.key === 'delete') {
      setVisible(true)
    }
  }

  const batchDeleteRows = async () => {
    try {
      for (const id of selectedRowKeys) {
        await ReviewService.remove(id)
        message.success(`Delete review success`)
        tableStore.state.refresh = Math.random()
      }

      setVisible(false)
    } catch (e) {
      message.error({ content: e.message, className: 'message-error' })
    }
  }

  const DeleteModal = () => {
    return (
      <Modal
        title={`Remove ${selectedRowKeys.length} sources`}
        visible={visible}
        onOk={() => {
          batchDeleteRows()
        }}
        onCancel={() => {
          setVisible(false)
        }}
        okText="Delete"
        cancelText="Cancel"
      >
        This can't be undone
      </Modal>
    )
  }

  return (
    <>
      <DeleteModal />

      <Row>
        <Col span={6}>
          {selectedRowKeys?.length > 0 ? (
            <Dropdown
              overlay={
                <Menu onClick={handleMenuClick}>
                  <Menu.Item key="delete">Delete</Menu.Item>
                </Menu>
              }
              trigger="click"
            >
              <Button type="primary">
                More Actions <CaretDownOutlined />
              </Button>
            </Dropdown>
          ) : (
            <></>
          )}
        </Col>
        <Col span={18}>
          <TableFilter
            dropdowns={[]}
            columns={filterColumns}
            hasDate={false}
            setCurrentFilters={setCurrentFilters}
          />
        </Col>
      </Row>

      {rows?.length > 0 ? (
        <Table
          columns={columns}
          dataSource={rows}
          onChange={(pagination, filters, sorter) => {
            tableStore.state.sort =
              sorter != null && !_.isEmpty(sorter?.columnKey) && !_.isEmpty(sorter?.order)
                ? `${sorter.columnKey ?? 'created_at'}:${
                    sorter.order === 'ascend' ? 'asc' : 'desc'
                  }`
                : null

            tableStore.state.currentPage = pagination.current
            tableStore.state.pageSize = pagination.pageSize
          }}
          pagination={{
            total,
            pageSize: state.pageSize,
            current: state.currentPage
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (selectedRowKeys?.length > 0) {
                  if (selectedRowKeys.includes(record.id)) {
                    const filterKeys = _.filter([...selectedRowKeys], function (o) {
                      return o !== record.id
                    })
                    setSelectedRowKeys([...filterKeys])
                  } else {
                    setSelectedRowKeys([...selectedRowKeys, record.id])
                  }
                } else {
                  navigate(`${record.id}`)
                }
              } // click row
            }
          }}
          rowSelection={rowSelection}
        />
      ) : (
        <Empty description={'No record found'} />
      )}
    </>
  )
}

export default ReviewTable
