import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, PageHeader, Button, Empty, Spin } from 'antd'
import useSWR from 'swr'
import { useSnapshot } from 'valtio'

import tableStore from '../../lib/store/user_table'
import UserService from '../../network/services/user'
import { serialize } from '../../network/request'
import UserTable from './UserTable'

const UserPage = () => {
  const navigate = useNavigate()
  const primaryAction = (
    <Button
      key="add"
      type="primary"
      onClick={() => {
        navigate('new')
      }}
    >
      Create User
    </Button>
  )

  return (
    <PageHeader title="All Users" extra={[primaryAction]}>
      <Card>
        <UserTableWrapper />
      </Card>
    </PageHeader>
  )
}

const UserTableWrapper = () => {
  const { state } = useSnapshot(tableStore)

  // use this to preload
  const { data: response, error } = useSWR(
    serialize(UserService.getAll, { page: 1, limit: state.pageSize })
  )

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response) {
    return <Spin />
  }

  const { total } = UserService.toPaginate(response)

  return (
    <>
      <UserTable total={total} />
      {/* preload next page */}
      {state.currentPage * state.pageSize < total && (
        <div style={{ display: 'none' }}>
          <UserTable total={total} overridePage={state.currentPage + 1} />
        </div>
      )}
    </>
  )
}

export default UserPage
