import { proxy, subscribe } from 'valtio'
import _ from 'lodash'

const initialTableAttr = {
  tab: '1',
  tabCount: {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0
  }
}

const storageName = 'order_dashboard'
let storedTableAttr = { ...initialTableAttr }
if (window.localStorage) {
  let result = JSON.parse(localStorage.getItem(storageName))
  if (!_.isEmpty(result)) {
    console.log(result)
    storedTableAttr = result
  }
}

const tableAttr = proxy({
  state: storedTableAttr,
  reset() {
    tableAttr.state = { ...initialTableAttr }
    localStorage.setItem(storageName, JSON.stringify(initialTableAttr))
  }
})

subscribe(tableAttr, () => {
  console.log('state.arr has changed to', tableAttr.state)
  localStorage.setItem(storageName, JSON.stringify(tableAttr.state))
})

export default tableAttr
