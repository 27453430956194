import { Tabs, Card, Badge } from 'antd'
import { useSnapshot } from 'valtio'
import DashboardTable from './DashboardTable'
import dashboardStore from '../lib/store/order_dashboard'
import { useContext } from 'react'
import { orderSelectContext } from '../pages/Dashboard'
import { useTranslation } from 'react-i18next'

const DashboardOrderTable = () => {
  const { TabPane } = Tabs
  const { state } = useSnapshot(dashboardStore)
  const { setOrderIds } = useContext(orderSelectContext)
  const { t } = useTranslation('translation')

  return (
    <Card>
      <Tabs
        defaultActiveKey="1"
        activeKey={state.tab}
        type="card"
        onChange={(key) => {
          dashboardStore.state.tab = key
          setOrderIds([])
        }}
      >
        <TabPane
          tab={
            <Badge count={state?.tabCount['1'] ?? 0} overflowCount={300}>
              <div style={{ marginTop: 10 }}>{t('Paid & Authorized')}</div>
            </Badge>
          }
          key="1"
        >
          <DashboardTable id={1} />
        </TabPane>

        <TabPane
          tab={
            <Badge count={state?.tabCount['2'] ?? 0} overflowCount={300}>
              <div style={{ marginTop: 10 }}>{t('Pending payment')}</div>
            </Badge>
          }
          key="2"
        >
          <DashboardTable id={2} />
        </TabPane>

        <TabPane
          tab={
            <Badge count={state?.tabCount['3'] ?? 0} overflowCount={300}>
              <div style={{ marginTop: 10 }}>{t('Out of stock')}</div>
            </Badge>
          }
          key="3"
        >
          <DashboardTable id={3} />
        </TabPane>

        <TabPane
          tab={
            <Badge count={state?.tabCount['4'] ?? 0} overflowCount={300}>
              <div style={{ marginTop: 10 }}>{t('Packaging')}</div>
            </Badge>
          }
          key="4"
        >
          <DashboardTable id={4} />
        </TabPane>

        <TabPane
          tab={
            <Badge count={state?.tabCount['5'] ?? 0} overflowCount={300}>
              <div style={{ marginTop: 10 }}>{t('Waiting to be shipped')}</div>
            </Badge>
          }
          key="5"
        >
          <DashboardTable id={5} />
        </TabPane>

        <TabPane
          tab={
            <Badge>
              <div style={{ marginTop: 10 }}>{t('Shipped')}</div>
            </Badge>
          }
          key="6"
        >
          <DashboardTable id={6} />
        </TabPane>

        <TabPane
          tab={
            <Badge>
              <div style={{ marginTop: 10 }}>{t('Partially shipped')}</div>
            </Badge>
          }
          key="8"
        >
          <DashboardTable id={8} />
        </TabPane>

        <TabPane
          tab={
            <Badge>
              <div style={{ marginTop: 10 }}>{t('Refunded')}</div>
            </Badge>
          }
          key="9"
        >
          <DashboardTable id={9} />
        </TabPane>

        <TabPane
          tab={
            <Badge>
              <div style={{ marginTop: 10 }}>{t('Cancelled')}</div>
            </Badge>
          }
          key="7"
        >
          <DashboardTable id={7} />
        </TabPane>
      </Tabs>
    </Card>
  )
}

export default DashboardOrderTable
