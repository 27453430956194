import client from '../request'

const getAll = `/reviews`

const get = (id) => {
  return `/reviews/${id}`
}

const create = (data) => {
  return client.post('/reviews', data)
}

const update = (id, data) => {
  return client.put(`/reviews/${id}`, data)
}

const toRow = (data) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        product_name: element.product?.product_name,
        key: element.id
      }
    })
  }

  return []
}

const toPaginate = (data) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

// axios
const remove = (id) => {
  return client.delete(`/reviews/${id}`)
}

// axios
const removeUpload = (id) => {
  return client.delete(`/review-uploads/${id}`)
}

const uploadAttachments = (id, data) => {
  return client.post(`/reviews/${id}/uploads`, data)
}

const ReviewService = {
  getAll,
  get,
  create,
  update,
  toRow,
  toPaginate,
  remove,
  removeUpload,
  uploadAttachments
}

export default ReviewService
