import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Col, Form, Input, Row, message, Checkbox, Select } from 'antd'

import YamatoSettingService from '../../network/services/yamatoSetting'

import Gaps from '../../components/Gaps'

const YamatoSettingForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh,
  updateAllowed = false,
  createAllowed = false,
  readAllowed = false
}) => {
  const navigate = useNavigate()

  const [isReadOnly, setIsReadOnly] = useState(false)
  useEffect(() => {
    //check the access level
    if ((initialValue != null && !readAllowed) || (initialValue === null && !createAllowed)) {
      navigate('/setting_dashboard')
    }
  })

  useEffect(() => {
    setIsReadOnly(!updateAllowed && initialValue != null)
  }, [updateAllowed, initialValue])

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    if (initialValue?.id) {
      // update
      try {
        const { data: result } = await YamatoSettingService.update(initialValue.id, values)
        if (result.success) {
          message.success('Update successful')
          refresh()
        }
      } catch (error) {
        message.error('Update yamato setting error ' + error.message)
      }
    } else {
      // new
      try {
        const { data: result } = await YamatoSettingService.create(values)
        if (result.success) {
          message.success('Create successful')
          navigate(`/dashboard/yamato_setting/${result.data.id}`)
        }
      } catch (error) {
        message.error('Create yamato setting error ' + error.message)
      }
    }

    setIsLoading(false)
  }

  return (
    <>
      <Form
        form={form}
        name="new-yamato-setting"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...initialValue
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
            <Card bordered={false}>
              <Form.Item
                name="setting_name"
                label="Setting Name"
                rules={[{ required: true, message: 'Please insert setting name' }]}
              >
                <Input readOnly={isReadOnly} />
              </Form.Item>

              <Form.Item
                name="customer_no"
                label="お客様番号"
                type="text"
                rules={[
                  {
                    required: true,
                    message: 'お客様番号 is required, the length must be excactly 12 digits',
                    max: 12,
                    min: 12
                  }
                ]}
              >
                <Input readOnly={isReadOnly} />
              </Form.Item>

              <Form.Item
                name="unchin_no"
                label="運賃番号"
                type="text"
                rules={[
                  { message: 'the length of 運賃番号 must be exactly 2 digits', max: 2, min: 2 }
                ]}
              >
                <Input readOnly={isReadOnly} />
              </Form.Item>

              <Form.Item name="handling_1" label="荷扱い１" type="text">
                <Input readOnly={isReadOnly} />
              </Form.Item>

              <Form.Item name="handling_2" label="荷扱い２" type="text">
                <Input readOnly={isReadOnly} />
              </Form.Item>

              <Form.Item
                name="kurijou_type"
                label="送り状種類"
                type="text"
                rules={[{ message: '送り状種類 must be selected', required: true }]}
              >
                <Select>
                  <Select.Option value="0">発払い（０）</Select.Option>
                  <Select.Option value="3">ＤＭ便（３）</Select.Option>
                  <Select.Option value="4">タイム（４）</Select.Option>
                  <Select.Option value="5">着払い（５）</Select.Option>
                  <Select.Option value="6">発払い（複数口）（６）</Select.Option>
                  <Select.Option value="7">ネコポス（７）</Select.Option>
                  <Select.Option value="8">宅急便コンパクト（８）</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="timezone"
                label="配達時間帯区分"
                type="text"
                rules={[
                  {
                    message: 'the length of 配達時間帯区分 must be exactly 4 digits',
                    max: 4,
                    min: 4
                  }
                ]}
              >
                <Input readOnly={isReadOnly} />
              </Form.Item>

              <Form.Item name="is_default" valuePropName="checked">
                <Checkbox disabled={isReadOnly}>Set as default Yamato setting</Checkbox>
              </Form.Item>
            </Card>
          </Col>
        </Row>

        <Gaps />
      </Form>
    </>
  )
}

export default YamatoSettingForm
