import client from '../request'

const getAll = `/blacklist`

const get = (id) => {
  return `/blacklist/${id}`
}

const create = (data) => {
  return client.post('/blacklist', data)
}

const update = (id, data) => {
  return client.put(`/blacklist/${id}`, data)
}

const toRow = (data) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element
      }
    })
  }

  return []
}

const toPaginate = (data) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

// axios
const remove = (id) => {
  return client.delete(`/blacklist/${id}`)
}

const BlacklistService = {
  getAll,
  get,
  create,
  update,
  toRow,
  toPaginate,
  remove
}

export default BlacklistService
