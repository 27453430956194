import {
  Button,
  Card,
  Col,
  Empty,
  Form,
  Input,
  PageHeader,
  Row,
  Space,
  Spin,
  Select,
  message,
  Divider,
  Typography,
  Collapse
} from 'antd'
import { useNavigate } from 'react-router-dom'
import { ScanOutlined } from '@ant-design/icons'
import React, { useRef, useEffect, useState, useMemo } from 'react'
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import Gaps from '../../components/Gaps'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import ProductVariantService from '../../network/services/productVariant'
import StockService from '../../network/services/stock'
import WarehouseService from '../../network/services/warehouse'
import { serialize } from '../../network/request'
const shiftCharCode = (Δ) => (c) => String.fromCharCode(c.charCodeAt(0) + Δ)
const toHalfWidth = (str) => str.replace(/[！-～]/g, shiftCharCode(-0xfee0))

const StockHandheld = () => {
  //const [barcode, setBarcode] = useState()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const barcodeInputRef = useRef(null)
  const [barcode, setBarcode] = useState('')
  const { t } = useTranslation('translation', { keyPrefix: 'orderScanning' })

  useEffect(() => {
    barcodeInputRef?.current?.focus()
  })

  const handleSubmit = (data) => {
    if (data.barcode) {
      const barcode = toHalfWidth(data.barcode)
      setBarcode(barcode)

      //TODO: scroll down below submit button
      form.resetFields()
    }
  }

  return (
    <PageHeader title=" " onBack={() => navigate('/scanner')}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Row justify="center">
          <Col span={8}>
            <div style={{ textAlign: 'center' }}>
              <ScanOutlined style={{ fontSize: 100 }} />
              <div>
                {t('Please scan a barcode of a tracking number with your handheld scanner')}
              </div>
              <Form form={form} onFinish={handleSubmit}>
                <Form.Item name="barcode">
                  <Input
                    ref={barcodeInputRef}
                    placeholder={t('Enter barcode')}
                    autoComplete="off"
                    // onChange={(e) => handleInput(e.target.value)}
                    // ref={barcodeInputRef}
                    style={{ opacity: 1 }}
                  />
                </Form.Item>
                <Button type="primary" onClick={() => form.submit()}>
                  Submit
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
        <Gaps />
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '50%' }}>
            <StockDetails barcode={barcode} />
          </div>
        </div>
      </Space>
    </PageHeader>
  )
}

const StockDetails = ({ barcode }) => {
  const [form] = Form.useForm()
  const { data, error, mutate } = useSWR(
    barcode
      ? serialize(ProductVariantService.getAll, {
          barcode
        })
      : null
  )
  const { data: stockQuality } = useSWR(StockService.getQualities)
  const { data: warehouseData } = useSWR(WarehouseService.getAll)
  const qualityId = Form.useWatch('quality_id', form)
  const warehouseId = Form.useWatch('warehouse_id', form)
  const currentInStock = useMemo(() => {
    if (!data || !qualityId || !warehouseId) return 0
    const variants = ProductVariantService.toRow(data)
    if (variants.length === 0) return 0
    const matchingStock = variants[0].stocks.find(
      (item) =>
        item.quality_id.toString() === qualityId.toString() &&
        item.warehouse_id.toString() === warehouseId.toString()
    )
    if (matchingStock) return matchingStock.total_stocks
    return 0
  }, [data, qualityId, warehouseId])

  if (!barcode) {
    return <></>
  }

  if (!data) {
    return <Spin />
  }

  if (error) {
    return (
      <Card>
        <Empty description={'Please try again'} />
      </Card>
    )
  }

  const variants = ProductVariantService.toRow(data)

  if (variants.length === 0) {
    return (
      <Card>
        <Empty description={'No matching stock found'} />
      </Card>
    )
  }

  const handleSubmit = async (values) => {
    if (parseInt(values.quantity) <= 0) {
      message.error('The adjustment quantity cannot be 0')
      return
    }

    const matchingStock = variant.stocks.find(
      (item) =>
        item.quality_id.toString() === values.quality_id.toString() &&
        item.warehouse_id.toString() === values.quality_id.toString()
    )

    if (!matchingStock) {
      try {
        const { data: result } = await StockService.create({
          variant_id: variant.id,
          quality_id: values.quality_id,
          warehouse_id: values.warehouse_id,
          initial_stock: values.quantity
        })
        if (result.success) {
          message.success('Adjust successful')
          await mutate()
          form.setFieldsValue({
            quantity: 0
          })
        }
      } catch (e) {
        message.error('Fail to adjust stock quantity')
      }
      return
    }

    try {
      const { data: result } = await StockService.adjust(matchingStock.id, {
        quantity: values.quantity
      })
      if (result.success) {
        message.success('Adjust successful')
        await mutate()
        form.setFieldsValue({
          quantity: 0
        })
      }
    } catch (e) {
      message.error('Fail to adjust stock quantity')
    }
  }

  const variant = variants[0]
  const warehouses = WarehouseService.toRow(warehouseData)
  const defaultStock = variant.stocks.find((item) => item.quality_id === 1)

  return (
    <Card>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          // stock_id: variant.stocks.find((item) => item.quality_id === 1)?.id,
          quantity: 1,
          quality_id: defaultStock?.quality_id,
          warehouse_id: defaultStock?.warehouse_id
        }}
        onFinish={handleSubmit}
      >
        <div>Item name : {variant.fullname}</div>
        <Gaps />
        <Typography.Text style={{ fontWeight: 'bold' }}>Stocks info</Typography.Text>
        <Collapse>
          {warehouses?.length > 0 &&
            warehouses.map((warehouse) => {
              const matchingStocks = variant.stocks.filter(
                (item) => item.warehouse_id.toString() === warehouse.id.toString()
              )
              if (matchingStocks.length > 0) {
                return (
                  <Collapse.Panel header={warehouse.warehouse_name}>
                    {matchingStocks.map((stock) => (
                      <Row gutter={[16, 16]}>
                        <Col span={8} style={{ fontWeight: 'bold' }}>
                          {stock.quality.name}
                        </Col>
                        <Col span={8} style={{ textAlign: 'right' }}>
                          {stock.total_stocks}
                        </Col>
                      </Row>
                    ))}
                    <Divider />
                    <Row gutter={[16, 16]} style={{ color: 'blue' }}>
                      <Col span={8} style={{ fontWeight: 'bold' }}>
                        Total stocks
                      </Col>
                      <Col span={8} style={{ textAlign: 'right' }}>
                        {matchingStocks.reduce((prev, curr) => prev + curr.total_stocks, 0)}
                      </Col>
                    </Row>
                  </Collapse.Panel>
                )
              }

              return <></>
            })}
        </Collapse>

        <Gaps />
        {/* <Form.Item name="stock_id" label="Stock type">
          <Select>
            {variant.stocks?.length > 0 &&
              variant.stocks.map((stock) => (
                <Select.Option value={stock.id}>
                  {stock.quality.name} - {stock.total_stocks}
                </Select.Option>
              ))}
          </Select>
        </Form.Item> */}
        <Form.Item name="quality_id" label="Stock type">
          <Select>
            {stockQuality?.length > 0 &&
              stockQuality.map((quality) => (
                <Select.Option value={quality.id}>{quality.name}</Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item name="warehouse_id" label="Warehouse">
          <Select>
            {warehouses?.length > 0 &&
              warehouses.map((warehouse) => (
                <Select.Option value={warehouse.id}>{warehouse.warehouse_name}</Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="quantity"
          label="Adjust amount"
          rules={[{ required: true, message: 'Please insert the adjust amount' }]}
        >
          <Input type="number" min={1} max={99999} />
        </Form.Item>
      </Form>
      <Typography.Text style={{ fontWeight: 'bold' }}>
        Current In Stock : {currentInStock}
      </Typography.Text>
      <Divider />
      <Button type="primary" onClick={() => form.submit()}>
        Confirm Ajust Stock
      </Button>
    </Card>
  )
}

export default StockHandheld
