import { proxy, subscribe } from 'valtio'
import _ from 'lodash'

const initialTableAttr = {
  // 1: { sort: null, currentPage: 1, pageSize: 10, prevUrl: '', refresh: 0 },
  1: { sort: null, filters: null },
  2: { sort: null, filters: null },
  3: { sort: null, filters: null },
  4: { sort: null, filters: null },
  5: { sort: null, filters: null },
  6: { sort: null, filters: null },
  7: { sort: null, filters: null }
}

const storageName = 'dashboard_table'
let storedTableAttr = { ...initialTableAttr }
if (window.localStorage) {
  let result = JSON.parse(localStorage.getItem(storageName))
  if (!_.isEmpty(result)) {
    console.log(result)
    storedTableAttr = result
  }
}

const tableAttr = proxy({
  state: storedTableAttr,
  reset() {
    tableAttr.state = { ...initialTableAttr }
    localStorage.setItem(storageName, JSON.stringify(initialTableAttr))
  }
})

subscribe(tableAttr, () => {
  console.log('state.arr has changed to', tableAttr.state)
  localStorage.setItem(storageName, JSON.stringify(tableAttr.state))
})

export default tableAttr
