import client from '../request'

// useSWR, return strings
const getAll = '/status'

// useSWR, return strings
const get = (id) => {
  return `/status/${id}`
}

// axios
const create = (data) => {
  return client.post('/status', data)
}

// axios
const update = (id, data) => {
  return client.put(`/status/${id}`, data)
}

// processing
const toRow = (data) => {
  if (data?.length > 0) {
    return data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

// processing
const toPaginate = (data) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

// axios
const remove = (id) => {
  return client.delete(`/status/${id}`)
}

const StatusService = {
  getAll,
  get,
  create,
  update,
  toPaginate,
  toRow,
  remove
}

export default StatusService
