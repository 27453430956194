import React, { useEffect, useState } from 'react'
import {
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  message,
  InputNumber,
  Button,
  Checkbox,
  Divider
} from 'antd'

import ProductVariantService from '../../network/services/productVariant'
import ProductService from '../../network/services/product'

import Gaps from '../../components/Gaps'
import { useForm } from 'antd/lib/form/Form'
import { Option } from 'antd/lib/mentions'
import Barcode from 'react-barcode'
import StockService from '../../network/services/stock'

const ProductVariantForm = ({
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh,
  id = 'new',
  productId,
  warehouses = null
}) => {
  const [form] = useForm()
  const [defaultVariantName, setDefaultVariantName] = useState('')
  const [option1, setOption1] = useState('')
  const [option2, setOption2] = useState('')
  const [option3, setOption3] = useState('')

  const handleAdjust = async (id, data) => {
    if (parseInt(data.quantity) <= 0) {
      message.error('The adjustment quantity cannot be 0')
      return
    }
    try {
      const { data: result } = await StockService.adjust(id, data)
      if (result.success) {
        message.success('Adjust successful')
        refresh()
      }
    } catch (e) {
      message.error('Fail to adjust stock quantity')
    }
  }

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    if (id !== 'new') {
      // update variant
      try {
        const { data: result } = await ProductVariantService.update(id, values)
        if (result.success) {
          message.success('Update successful')
          refresh()
        }
      } catch (error) {
        message.error('Update variant error ' + error.message)
      }
    } else {
      // new variant
      try {
        const { data: result } = await ProductService.createVariant(productId, values)
        if (result.success) {
          message.success('Create successful')
          refresh()
        }
      } catch (error) {
        message.error('Create variant error ' + error.message)
      }
    }

    setIsLoading(false)
  }

  useEffect(() => {
    setDefaultVariantName(`${option1} / ${option2} / ${option3}`)
  }, [option1, option2, option3])

  return (
    <div>
      <Card>
        {id === 'new' && (
          <Row gutter={[16, 16]}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
              <h3>New variant</h3>
            </Col>
          </Row>
        )}
        <Form
          form={form}
          onFinish={handleSubmit}
          initialValues={
            id === 'new'
              ? {
                  availability: true
                }
              : {
                  ...initialValue
                }
          }
        >
          <Row gutter={[16, 16]}>
            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 8 }}>
              <Form.Item name="variant_name" label="Variant Name">
                <Input
                  placeholder={id === 'new' ? defaultVariantName : initialValue.variant_name}
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 8 }}>
              <Form.Item
                name="sku"
                label="SKU"
                rules={[{ required: true, message: 'Please insert SKU' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 8 }}>
              <Form.Item name="barcode" label="Barcode" rules={[{ required: false }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          {id === 'new' && (
            <Row gutter={[16, 16]}>
              <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 8 }}>
                <Form.Item
                  name="initial_stock"
                  label="Initial Stock"
                  rules={[{ required: true, message: 'Please insert initial stock' }]}
                >
                  <InputNumber min={0} />
                </Form.Item>
              </Col>

              <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 8 }}>
                <Form.Item name="warehouse_id" label="Warehouse">
                  <Select>
                    {warehouses?.length > 0 &&
                      warehouses.map((warehouse) => (
                        <Option key={warehouse.id} value={warehouse.id}>
                          {warehouse.warehouse_name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row gutter={[16, 16]}>
            <Col xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 5 }}>
              <Form.Item
                name="option_1"
                label="Option 1"
                rules={[{ required: true, message: 'Please insert at least option 1' }]}
              >
                <Input onChange={(e) => setOption1(e.target.value)} />
              </Form.Item>
            </Col>

            <Col xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 5 }}>
              <Form.Item name="option_2" label="Option 2">
                <Input onChange={(e) => setOption2(e.target.value)} />
              </Form.Item>
            </Col>

            <Col xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 5 }}>
              <Form.Item name="option_3" label="Option 3">
                <Input onChange={(e) => setOption3(e.target.value)} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 8 }}>
              <Form.Item name="price" label="Price">
                <InputNumber min={0.0} precision={2} />
              </Form.Item>
            </Col>

            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 8 }}>
              <Form.Item name="weight" label="Weight">
                <InputNumber min={0.0} precision={2} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 8 }}>
              <Form.Item name="availability" valuePropName="checked">
                <Checkbox>Available?</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        {id !== 'new' && initialValue.stocks.length > 0 && (
          <>
            <Card>
              <Row gutter={[16, 16]}>
                <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 8 }}>
                  <h3>Stocks:</h3>
                </Col>
              </Row>
              <Divider />

              {initialValue.stocks.map((stock) => (
                <Form
                  onFinish={(data) => handleAdjust(stock.id, data)}
                  autoComplete="off"
                  key={stock.id}
                >
                  <Row gutter={[16, 16]}>
                    <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 10 }}>
                      <h4>{`[${stock?.warehouse?.warehouse_name}] [${stock.quality.name}] [${stock.total_stocks} in stock]`}</h4>
                    </Col>

                    <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6 }}>
                      <Form.Item
                        name="quantity"
                        label="Adjustment"
                        rules={[{ required: true, message: 'Please insert a value' }]}
                      >
                        <InputNumber precision={0} step={1} />
                      </Form.Item>
                    </Col>

                    <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6 }}>
                      <Button type="primary" htmlType="submit">
                        Confirm adjust
                      </Button>
                    </Col>
                  </Row>
                  <Divider style={{ marginTop: 0, marginBottom: 0 }} />
                </Form>
              ))}
            </Card>
            <Gaps />
          </>
        )}

        {id !== 'new' && initialValue.barcode && (
          <Row gutter={[16, 16]}>
            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 8 }}>
              <Barcode value={initialValue.barcode} />
            </Col>
          </Row>
        )}

        <Button
          key={'save'}
          type="primary"
          onClick={() => {
            form.submit()
          }}
          loading={isLoading}
        >
          Save
        </Button>
      </Card>
      <Gaps />
    </div>
  )
}

export default ProductVariantForm
