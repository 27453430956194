import { useState } from 'react'
import { Button } from 'antd'
import BarcodeScannerComponent from 'react-qr-barcode-scanner'

const BarcodeScanner = () => {
  const [scan, setScan] = useState(false)
  const [barcode, setBarcode] = useState('Not Found')

  const handleScan = () => {
    setScan(!scan)
  }

  return (
    <>
      <Button onClick={() => handleScan()}>
        {scan ? <p>Close Scanner</p> : <p>Open Scanner</p>}
      </Button>

      {scan && (
        <BarcodeScannerComponent
          width={500}
          height={500}
          onUpdate={(err, result) => {
            console.log(result)
            if (result) {
              setBarcode(result.text)
            } else setBarcode('Not Found')
          }}
        />
      )}
      <p>{barcode}</p>
    </>
  )
}

export default BarcodeScanner
