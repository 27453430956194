import client from '../request'

// useSWR, return strings
const getAll = '/warehouses'

// useSWR, return strings
const get = (id) => {
  return `/warehouses/${id}`
}

const getDefault = 'warehouses/default'

// axios
const create = (data) => {
  return client.post('/warehouses', data)
}

// axios
const update = (id, data) => {
  return client.put(`/warehouses/${id}`, data)
}

// processing
const toRow = (data) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

// processing
const toPaginate = (data) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

// axios
const remove = (id) => {
  return client.delete(`/warehouses/${id}`)
}

const WarehouseService = {
  getAll,
  get,
  getDefault,
  create,
  update,
  toPaginate,
  toRow,
  remove
}

export default WarehouseService
