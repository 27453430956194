import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, Col, Form, Row, Select, message, InputNumber } from 'antd'

import StockService from '../../network/services/stock'

import Gaps from '../../components/Gaps'

const StockForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh,
  warehouses,
  variants,
  qualities,
  handleVariantSearch,
  updateAllowed = false,
  createAllowed = false,
  readAllowed = false,
  deleteAllowed = false
}) => {
  const navigate = useNavigate()
  const { id } = useParams()

  const { Option } = Select

  const [isReadOnly, setIsReadOnly] = useState(false)
  useEffect(() => {
    //check the access level
    if ((initialValue != null && !readAllowed) || (initialValue === null && !createAllowed)) {
      navigate('/stocks')
    }
  })

  useEffect(() => {
    setIsReadOnly(!updateAllowed && initialValue != null)
  }, [updateAllowed, initialValue])

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    if (initialValue?.id) {
      // update event
      try {
        const { data: result } = await StockService.update(initialValue.id, values)
        if (result.success) {
          message.success('Update successful')
          refresh()
        }
      } catch (error) {
        message.error('Update stock error ' + error.message)
      }
    } else {
      // new event
      try {
        const { data: result } = await StockService.create(values)
        if (result.success) {
          message.success('Create successful')
          navigate(`/dashboard/stocks/${result.data.id}`)
        }
      } catch (error) {
        message.error('Create stocks error ' + error.message)
      }
    }

    setIsLoading(false)
  }

  return (
    <>
      <Form
        form={form}
        name="new-event"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...initialValue,
          condition_match: initialValue == null ? 'all' : initialValue.condition_match,
          sort_order: initialValue == null ? 'ascending' : initialValue.sort_order
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
            <Card bordered={false}>
              {id !== 'new' && (
                <Form.Item name="total_stocks" label="Total stocks">
                  <InputNumber readOnly />
                </Form.Item>
              )}

              {id === 'new' && (
                <Form.Item
                  name="initial_stock"
                  label="Initial stock"
                  rules={[{ required: true, message: 'Please insert quantity' }]}
                >
                  <InputNumber min={0} />
                </Form.Item>
              )}

              <Form.Item name="variant_id" label="Product variant">
                <Select
                  showSearch
                  placeholder="Select a customer with email or name"
                  onSearch={handleVariantSearch}
                  disabled={initialValue !== null}
                  filterOption={false}
                >
                  {variants?.length > 0 &&
                    variants.map((variant) => {
                      return (
                        <Option key={variant.id} value={variant.id}>
                          {variant?.product?.product_name} - {variant?.variant_name}
                        </Option>
                      )
                    })}
                </Select>
              </Form.Item>

              <Form.Item name="warehouse_id" label="Warehouse">
                <Select disabled={isReadOnly}>
                  {warehouses?.length > 0 &&
                    warehouses.map((warehouse) => (
                      <Option key={warehouse.id} value={warehouse.id}>
                        {warehouse.warehouse_name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item name="quality_id" label="Quality">
                <Select disabled={isReadOnly}>
                  {qualities?.length > 0 &&
                    qualities.map((quality) => (
                      <Option key={quality.id} value={quality.id}>
                        {quality.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Card>
          </Col>
        </Row>

        <Gaps />
      </Form>
    </>
  )
}

export default StockForm
