import client from '../request'

const getAll = `/sources`

const get = (id) => {
  return `/sources/${id}`
}

const create = (data) => {
  return client.post('/sources', data)
}

const update = (id, data) => {
  return client.put(`/sources/${id}`, data)
}

const toRow = (data) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

const toPaginate = (data) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

// axios
const remove = (id) => {
  return client.delete(`/sources/${id}`)
}

const SourceService = {
  getAll,
  get,
  create,
  update,
  toRow,
  toPaginate,
  remove
}

export default SourceService
