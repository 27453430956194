import React, { useState, useEffect } from 'react'
import { Space, Form, Card, Typography, Input, Button, Row, Col } from 'antd'
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons'
import { useSnapshot } from 'valtio'
import { Navigate, useLocation, Link } from 'react-router-dom'

import authStore, { login } from '../../lib/store/auth'
import AuthService from '../../network/services/auth'

const SignIn = () => {
  let location = useLocation()
  const {
    state: { token: isLoggedIn }
  } = useSnapshot(authStore)

  const [form] = Form.useForm()
  const [redirectToReferrer, setRedirectToReferrer] = useState(false)
  const [formError, setFormError] = useState(null)

  useEffect(() => {
    if (isLoggedIn) {
      setRedirectToReferrer(true)
    }
  }, [isLoggedIn])

  const handleLogin = async (values) => {
    try {
      const { data: result } = await AuthService.login(values)

      const profile = result.user
      const jwt = result.jwt
      const sources = result.available_sources

      login({ profile, token: jwt.token, sources: sources })
      //   globalStore.clearMenu()
      //   tableStore1.reset()
      //   tableStore2.reset()
    } catch (e) {
      console.log(e)
      setFormError(e.message)
    }
  }

  let { from } = location.state || { from: { pathname: '/dashboard' } }

  if (redirectToReferrer) {
    return <Navigate to={from} />
  }

  return (
    <Row justify="center" align="middle" style={{ height: '100vh', width: '100%' }}>
      <Col>
        <Space direction="vertical">
          <Link to="/dashboard">
            <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
              <Typography.Title>Ergofinite</Typography.Title>
            </div>
          </Link>

          <Card style={{ minWidth: '400px' }}>
            <Form onFinish={handleLogin} autoComplete="off" form={form}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please insert your email'
                  }
                ]}
              >
                <Input size="large" placeholder="Email" autoComplete="off" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please insert your password'
                  }
                ]}
                style={{ marginBottom: 4 }}
              >
                <Input.Password
                  size="large"
                  type="password"
                  placeholder="Password"
                  autoComplete="off"
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
              </Form.Item>

              <p style={{ color: 'red', textTransform: 'capitalize' }}>{formError}</p>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Sign In
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Space>
      </Col>
    </Row>
  )
}

export default SignIn
