import React from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Empty, Spin, PageHeader, Button } from 'antd'
import useSWR from 'swr'

import { serialize } from '../../network/request'
import ReviewService from '../../network/services/review'
import ProductService from '../../network/services/product'
import CustomerService from '../../network/services/customer'
import ReviewForm from './ReviewForm'
const shiftCharCode = (Δ) => (c) => String.fromCharCode(c.charCodeAt(0) + Δ)
const toHalfWidth = (str) => str?.replace(/[！-～]/g, shiftCharCode(-0xfee0))

const ReviewDetail = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [productSearch, setProductSearch] = useState()
  const [customerSearch, setCustomerSearch] = useState('')
  const [delayProductSearch, setDelayProductSearch] = useState()
  const [delayCustomerSearch, setDelayCustomerSearch] = useState()

  const saveAction = (
    <Button
      key={'save'}
      type="primary"
      onClick={() => {
        form.submit()
      }}
      loading={isLoading}
    >
      Save
    </Button>
  )
  const discardAction = (
    <Button
      key={'discard'}
      type="ghost"
      onClick={async () => {
        await ReviewService.remove(id)
        navigate('/dashboard/reviews')
      }}
    >
      Discard
    </Button>
  )

  const handleCustomerSearch = (value) => {
    clearTimeout(delayCustomerSearch)
    const asciiValue = value?.length > 0 ? toHalfWidth(value) : ''
    const deplayDebounce = setTimeout(() => {
      setCustomerSearch(asciiValue)
    }, 500)
    setDelayCustomerSearch(deplayDebounce)
  }

  const handleProductSearch = (value) => {
    clearTimeout(delayProductSearch)
    const asciiValue = value?.length > 0 ? toHalfWidth(value) : ''
    const deplayDebounce = setTimeout(() => {
      setProductSearch(asciiValue)
    }, 500)
    setDelayProductSearch(deplayDebounce)
  }

  // use this to preload
  const {
    data: response,
    error,
    mutate
  } = useSWR(serialize(id !== 'new' ? ReviewService.get(id) : null))

  const { data: customers } = useSWR(
    serialize(CustomerService.getAll, {
      search: customerSearch
    })
  )

  const { data: products } = useSWR(
    serialize(ProductService.getAll, {
      product_name: productSearch
    })
  )

  const customerRow = CustomerService.toRow(customers)
  const productRow = ProductService.toRow(products)

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response && id !== 'new') {
    return (
      <PageHeader>
        <Spin />
      </PageHeader>
    )
  }

  const refresh = () => {
    if (id != null) {
      if (id === 'new') {
      } else {
        mutate()
      }
    }
  }

  return (
    <PageHeader
      title={id === 'new' ? 'New Review' : 'Edit Review'}
      extra={[discardAction, saveAction]}
      onBack={() => navigate('/dashboard/reviews')}
    >
      <ReviewForm
        form={form}
        initialValue={response}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        refresh={refresh}
        customers={customerRow}
        handleCustomerSearch={handleCustomerSearch}
        products={productRow}
        handleProductSearch={handleProductSearch}
        updateAllowed={true}
        createAllowed={true}
        readAllowed={true}
        deleteAllowed={true}
      />
    </PageHeader>
  )
}

export default ReviewDetail
