import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Col, Form, Input, Row, message } from 'antd'

import SourceService from '../../network/services/source'

import Gaps from '../../components/Gaps'

const SourceForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh,
  updateAllowed = false,
  createAllowed = false,
  readAllowed = false,
  deleteAllowed = false
}) => {
  const navigate = useNavigate()

  const [isReadOnly, setIsReadOnly] = useState(false)
  useEffect(() => {
    //check the access level
    if ((initialValue != null && !readAllowed) || (initialValue === null && !createAllowed)) {
      navigate('/events')
    }
  })

  useEffect(() => {
    setIsReadOnly(!updateAllowed && initialValue != null)
  }, [updateAllowed, initialValue])

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    if (initialValue?.id) {
      // update return status
      try {
        const { data: result } = await SourceService.update(initialValue.id, values)
        if (result) {
          message.success('Update successful')
          refresh()
        }
      } catch (error) {
        message.error('Update source error ' + error.message)
      }
    } else {
      // new return status
      try {
        const { data: result } = await SourceService.create(values)
        if (result.success) {
          message.success('Create successful')
          navigate(`/dashboard/source/${result.data.id}`)
        }
      } catch (error) {
        message.error('Create source error ' + error.message)
      }
    }

    setIsLoading(false)
  }

  return (
    <>
      <Form
        form={form}
        name="new-source"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...initialValue,
          condition_match: initialValue == null ? 'all' : initialValue.condition_match,
          sort_order: initialValue == null ? 'ascending' : initialValue.sort_order
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
            <Card bordered={false}>
              <Form.Item
                name="source_name"
                label="Name"
                rules={[{ required: true, message: 'Please insert source name' }]}
              >
                <Input readOnly={isReadOnly} />
              </Form.Item>
            </Card>
          </Col>
        </Row>

        <Gaps />
      </Form>
    </>
  )
}

export default SourceForm
