import React from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Empty, Spin, PageHeader, Button } from 'antd'
import useSWR from 'swr'

import { serialize } from '../../network/request'
import EventService from '../../network/services/event'
import StatusService from '../../network/services/status'
import EventForm from './EventForm'
import SourceService from '../../network/services/source'
import StockService from '../../network/services/stock'

const EventDetail = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)

  const saveAction = (
    <Button
      key={'save'}
      type="primary"
      onClick={() => {
        form.submit()
      }}
      loading={isLoading}
    >
      Save
    </Button>
  )
  const discardAction = (
    <Button
      key={'discard'}
      type="ghost"
      onClick={() => {
        navigate('/dashboard/events')
      }}
    >
      Discard
    </Button>
  )

  // use this to preload
  const {
    data: response,
    error,
    mutate
  } = useSWR(serialize(id !== 'new' ? EventService.get(id) : null))

  //preload the dropdown menu content
  const { data: eventTypes } = useSWR(serialize(EventService.getTypes))
  const { data: statuses } = useSWR(serialize(StatusService.getAll))
  const { data: sources } = useSWR(serialize(SourceService.getAll))
  const { data: stocks } = useSWR(serialize(StockService.getAll))

  const sourceRows = SourceService.toRow(sources)
  const stockRows = StockService.toRow(stocks)

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response && id !== 'new') {
    return (
      <PageHeader>
        <Spin />
      </PageHeader>
    )
  }

  const refresh = () => {
    if (id != null) {
      if (id === 'new') {
      } else {
        mutate()
      }
    }
  }

  return (
    <PageHeader
      title={id === 'new' ? 'New Event' : 'Edit Event'}
      extra={[discardAction, saveAction]}
      onBack={() => navigate('/dashboard/events')}
    >
      <EventForm
        form={form}
        initialValue={response}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        refresh={refresh}
        statuses={statuses}
        eventTypes={eventTypes}
        sources={sourceRows}
        stocks={stockRows}
        updateAllowed={true}
        createAllowed={true}
        readAllowed={true}
        deleteAllowed={true}
      />
    </PageHeader>
  )
}

export default EventDetail
