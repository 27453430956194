import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, PageHeader, Button, Empty, Spin } from 'antd'
import useSWR from 'swr'
import { useSnapshot } from 'valtio'

import tableStore from '../../lib/store/product_table'
import ProductService from '../../network/services/product'
import { serialize } from '../../network/request'
import ProductTable from './ProductTable'

const ProductPage = () => {
  const navigate = useNavigate()

  const handleCSVImport = () => {}

  const primaryAction = (
    <Button
      key="add"
      type="primary"
      onClick={() => {
        navigate('new')
      }}
    >
      Create Product
    </Button>
  )

  const secondaryAction = (
    <Button
      key="importshopify"
      type="primary"
      onClick={async () => {
        // const res = await ProductService.importFrom('shopify', {
        //   product_ids: [7326129225896]
        // })
        // console.log(res)
        navigate('/dashboard/products/import/shopify')
      }}
    >
      Import from Shopify
    </Button>
  )

  const thirdAction = (
    <Button key="importcsv" type="primary" onClick={handleCSVImport}>
      Import with CSV
    </Button>
  )

  return (
    <PageHeader title="All Products" extra={[primaryAction, secondaryAction, thirdAction]}>
      <Card>
        <ProductTableWrapper />
      </Card>
    </PageHeader>
  )
}

const ProductTableWrapper = () => {
  const { state } = useSnapshot(tableStore)

  // use this to preload
  const { data: response, error } = useSWR(
    serialize('/products', { page: 1, limit: state.pageSize })
  )

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response) {
    return <Spin />
  }

  const { total } = ProductService.toPaginate(response)

  return (
    <>
      <ProductTable total={total} />
      {/* preload next page */}
      {state.currentPage * state.pageSize < total && (
        <div style={{ display: 'none' }}>
          <ProductTable total={total} overridePage={state.currentPage + 1} />
        </div>
      )}
    </>
  )
}

export default ProductPage
